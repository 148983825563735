import { OverlayTrigger, Tooltip } from "react-bootstrap";

const ICResetPass = () => {
  return (
    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Reset Password</Tooltip>}>
    <svg xmlns="http://www.w3.org/2000/svg" width="32.135" height="32.813" viewBox="0 0 32.135 32.813">
  <g id="Group_923" data-name="Group 923" transform="translate(0.001 0)">
    <path id="Subtraction_6" data-name="Subtraction 6" d="M-4576.626-428.157H-4587.4a3.3,3.3,0,0,1-3.2-3.385v-4.749a3.3,3.3,0,0,1,3.2-3.385h10.779a3.3,3.3,0,0,1,3.2,3.385v4.749A3.3,3.3,0,0,1-4576.626-428.157Zm-1.337-6.759a1.444,1.444,0,0,0-1.442,1.442,1.444,1.444,0,0,0,1.442,1.442,1.444,1.444,0,0,0,1.442-1.442A1.444,1.444,0,0,0-4577.963-434.917Zm-4.051,0a1.443,1.443,0,0,0-1.441,1.442,1.443,1.443,0,0,0,1.441,1.442,1.444,1.444,0,0,0,1.442-1.442A1.444,1.444,0,0,0-4582.014-434.917Zm-4.054,0a1.444,1.444,0,0,0-1.442,1.442,1.444,1.444,0,0,0,1.442,1.442,1.444,1.444,0,0,0,1.442-1.442A1.444,1.444,0,0,0-4586.068-434.917Z" transform="translate(4598.188 454.241)"    strokeMiterlimit="10" strokeWidth="1"/>
    <path id="Path_3574" data-name="Path 3574" d="M19.874,14.761h-2V11.354c0-1.183-.712-2.183-1.554-2.183H14.868c-.842,0-1.554,1-1.554,2.183v3.407h-2V11.354c0-2.342,1.594-4.247,3.554-4.247H16.32c1.96,0,3.554,1.905,3.554,4.247Z" transform="translate(0.577)"  />
    <path id="Path_3578" data-name="Path 3578" d="M16.032,32.812A16.067,16.067,0,0,1,2.971,7.441,16.071,16.071,0,0,1,21.562,1.647a16.171,16.171,0,0,1,6.571,4.491,1,1,0,1,1-1.5,1.322A14.065,14.065,0,1,0,7.921,28.21,14.064,14.064,0,0,0,29.933,14.4a1,1,0,0,1,1.972-.334,16.065,16.065,0,0,1-13.157,18.52A16.269,16.269,0,0,1,16.032,32.812Z"  />
    <path id="Path_3579" data-name="Path 3579" d="M21.427,8.979a1,1,0,0,1-.159-1.987L26.5,6.143,25.307,1.236A1,1,0,1,1,27.251.764L28.7,6.721a1,1,0,0,1-.812,1.223l-6.3,1.022A1.007,1.007,0,0,1,21.427,8.979Z"  />
    <rect id="Rectangle_473" data-name="Rectangle 473" width="32.132" height="32.811" transform="translate(0 0)" fill="none"/>
  </g>
</svg>

    </OverlayTrigger>
  );
};

export default ICResetPass;
