/**
 * @file   src\containers\WorkAloneModeDetails.tsx
 * @brief  Work Alone Mode checkin Details page.
 * @date   Feb, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import React, { useEffect } from "react";
import ICBack from "../assets/img/icons/Back";
import { Badge, Button, Col, Row, Table } from "react-bootstrap";
import TableHeader from "../components/common/TableHeader";
import ICTableExpand from "../assets/img/icons/TableExpand";
import {
  ONE,
  ZERO,
  PAGE_SIZE_20,
  MARKED_SAFE,
  NEED_HELP,
  HTTP_RESPONSE_STATUS_200,
  DATE_FORMAT_TIME,
  DATE_FORMAT_WITHOUT_TIME_FULL_YEAR,
  DATE_FORMAT_WITH_TIME_FULL_YEAR,
  SESSION_ACTIVE,
  HELP_CANCELLED,
  API_CALL_INTERVAL,
  SAFETY_TEAM_ALERTED,
  FIRST_WARNING_UNRESPONSIVE,
  TWO,
  SECOND_WARNING_UNRESPONSIVE,
  tableHeaders,
  NEED_HELP_STATUS,
  DATE_FORMAT_WITH_TIME_PREFIX,
  csvDownload,
  HIGH_RISK_STATUS,
  HIGH_RISK_TITLE,
  LOW_RISK_TITLE,
  workaloneModeDetailsPage,
  WIDTH_250,
} from "../constants/common";
import { useNavigate, useParams } from "react-router-dom";
import { fetchAfterHourSessionList } from "../Redux/actions/afterHour";
import { useAppSelector, useAppDispatch } from "../Redux/hooks";
import {
  dateConversion,
  dateFormat,
  decryptText,
  downloadCSVForChkInDetails,
  getTimeZone,
} from "../helpers/common";
import { phoneFormat } from "../helpers/validations";
import { pageURLs } from "../constants/pageURLs";
import Paginate from "../components/common/Paginate";
import AddEditNote from "../components/common/AddEditNote";
import { ResponseObjects } from "../interfaces/AxiosResponse";
import {
  updateAfterHourNotes,
  disableAfterHourCheckIn,
  getWorkAloneModeDetailsForCSVDownload,
} from "../services/afterHourServices";
import Strings from "../assets/strings/Strings.json";
import { toast } from "react-toastify";
import DeleteConfModal from "../components/common/DeleteConfModal";
import { PageParams } from "../interfaces/Paginate";

const WorkAloneModeDetails = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { ID } = useParams();
  const deCryptedId = decryptText(ID);
  const afterHourDetails = useAppSelector(
    (state: any) => state.afterHour?.afterHourDetails?.list?.afterHourData
  );
  const timeZone = getTimeZone();
  const afterHourSessionList = useAppSelector(
    (state: any) => state.afterHour?.afterHourDetails?.list
  );

  const afterHourIsLoading = useAppSelector(
    (state: any) => state.afterHour?.afterHourDetails?.loading
  );

  const [detailsData, setDetailsData] = React.useState<any>(null);
  const [sessionList, setSessionList] = React.useState<any>([]);
  const [page, setPage] = React.useState<number>(ONE);
  const [perPage, setPerPage] = React.useState<number>(PAGE_SIZE_20);
  const [load, setLoad] = React.useState<boolean>(true);
  const [initialLoad, setInitialLoad] = React.useState<boolean>(true);
  const [totalRecord, setTotalRecord] = React.useState<number>(ZERO);
  const [showDisable, setShowDisable] = React.useState<boolean>(false);
  const [endSessionBtnDisable, setEndSessionBtnDisable] =
    React.useState<boolean>(false);

  const columns = [
    { title: tableHeaders.common.timestamp, isSort: false },
    { title: tableHeaders.common.response, isSort: false },
    { title: tableHeaders.common.notes, isSort: false },
    { title: tableHeaders.common.toggle, isSort: false },
  ];
  const NEED_ASSISTANCE_CLASS = "needassistance";
  const UN_RESPONSIVE_CLASS = "unresponsive";
  const ACTIVE_SESSION = "ACTIVE SESSION";

  // Handle to calling the after-hour session list API in each component render except rerendering
  useEffect(() => {
    getAfterHourSessionDetails({ pageIndex: ONE, pageSize: PAGE_SIZE_20 });
  }, []);

  // Handle to calling the after-hour details and session list API's in every 30s intervals if the session is active for update the list-
  //and details without refresh the page.
  useEffect(() => {
    if (detailsData?.session_Status === SESSION_ACTIVE) {
      const sessionListIntervalId = setInterval(() => {
        getAfterHourSessionDetails({ pageIndex: ONE, pageSize: perPage });
        setPage(ONE);
      }, API_CALL_INTERVAL);

      // Cleanup function to clear interval on component unmount
      return () => {
        clearInterval(sessionListIntervalId);
      };
    }
  }, [detailsData?.session_Status, perPage]);

  //Dispatch after hour session list redux action for invoking the session list api
  const getAfterHourSessionDetails = ({ pageIndex, pageSize }: PageParams) => {
    dispatch(
      fetchAfterHourSessionList({
        id: deCryptedId,
        pageNo: pageIndex,
        pageSize: pageSize,
      })
    );
  };

  // Fetch the after-hour details from redux store and set into the local state
  useEffect(() => {
    if (!afterHourIsLoading) {
      loadAfterHourDetails();
    }
  }, [afterHourDetails]);

  // Fetch the after-hour session list from redux store and set into the local state
  useEffect(() => {
    if (!afterHourIsLoading) {
      loadAfterHourSessionList();
    }
  }, [afterHourSessionList]);

  // Handle to close end-session modal
  const closeDisableActive = () => {
    setShowDisable(false);
  };

  // Handle to show end-session modal
  const showDisableActive = (e: any) => {
    e.preventDefault();
    setShowDisable(true);
  };

  // Handle setting the response from the after-hours details API call into state.
  const loadAfterHourDetails = () => {
    setInitialLoad(false);
    const Data = afterHourDetails;
    if (Data) {
      setDetailsData(Data);
    } else setDetailsData(null);
  };

  // Handle setting the response from the after-hours session list API call into state.
  const loadAfterHourSessionList = () => {
    setLoad(false);
    const list = afterHourSessionList?.list;
    if (list) {
      setSessionList(list);
      setTotalRecord(afterHourSessionList?.totalRecords);
    }
  };

  // handle length of shift
  const handleLengthshift = (
    status: number,
    session_Start: Date,
    projected_EndTime: Date,
    session_EndTime: Date
  ) => {
    const currentTime: Date = new Date();
    const startTime: Date = new Date(session_Start);
    let endTime: Date;
    if (session_EndTime) {
      endTime = new Date(session_EndTime);
    } else if (projected_EndTime) {
      endTime = new Date(projected_EndTime);
    } else {
      endTime = currentTime;
    }
    if (endTime) {
      return shiftDifference(endTime, startTime);
    }
  };

  //Calculating shift length
  const shiftDifference = (endTime: Date, startTime: Date) => {
    const timeDifferenceMs = endTime.getTime() - startTime.getTime();
    const hoursDifference = Math.floor(timeDifferenceMs / (1000 * 60 * 60));
    const minutesDifference = Math.floor(
      (timeDifferenceMs % (1000 * 60 * 60)) / (1000 * 60)
    );

    const MINS_TITLE = "mins";
    const HOURS_TITLE = "hours";
    const HOUR_TITLE = "hour";
    if (hoursDifference === ZERO) {
      return `${minutesDifference} ${MINS_TITLE}`;
    } else if (hoursDifference === ONE) {
      return `${hoursDifference} ${HOUR_TITLE} ${minutesDifference} ${MINS_TITLE}`;
    } else
      return `${hoursDifference} ${HOURS_TITLE} ${minutesDifference} ${MINS_TITLE}`;
  };

  //Calculating Remaining time of shift
  const remainingTime = (endTime: Date) => {
    const currentTime: Date = new Date();
    const projectedEndTime: Date = new Date(endTime);
    const timeDifferenceMs = projectedEndTime.getTime() - currentTime.getTime();

    const hoursDifference = Math.floor(timeDifferenceMs / (1000 * 60 * 60));

    const minutesDifference = Math.floor(
      (timeDifferenceMs % (1000 * 60 * 60)) / (1000 * 60)
    );

    const MINS_TITLE = "mins";
    const HOURS_TITLE = "hours";
    const HOUR_TITLE = "hour";

    if (hoursDifference >= 0 && minutesDifference >= 0) {
      if (hoursDifference === ZERO) {
        return `${minutesDifference} ${MINS_TITLE}`;
      } else if (hoursDifference === ONE) {
        return `${hoursDifference} ${HOUR_TITLE} ${minutesDifference} ${MINS_TITLE}`;
      } else
        return `${hoursDifference} ${HOURS_TITLE} ${minutesDifference} ${MINS_TITLE}`;
    } else {
      return `${ZERO} ${MINS_TITLE}`;
    }
  };

  // page change and state change
  const pageChange = (page: number) => {
    getAfterHourSessionDetails({ pageIndex: page, pageSize: perPage });
    if (page) {
      setPage(page);
    }
  };

  // page size change and state change
  const perPageChange = (page: number) => {
    getAfterHourSessionDetails({ pageIndex: ONE, pageSize: page });
    if (page) {
      setPage(ONE);
      setPerPage(page);
    }
  };

  // Handle updating the note state and calling the update After-hour session API.
  const updateNote = async (id: number, value: number | string) => {
    const payload = {
      sessionId: id,
      notes: value,
    };
    const response: ResponseObjects = await updateAfterHourNotes(payload);
    if (response?.status === HTTP_RESPONSE_STATUS_200) {
      const copyList = [...sessionList];
      const newState = copyList.map((obj) => {
        if (obj.sessionId === id) {
          return { ...obj, notes: value, toggleDisabled: false };
        }
        return obj;
      });
      setSessionList(newState);
    }
    return response?.status;
  };

  //Handle status title and date showing based on certails conditions
  const statusTitlesAndDate = (
    status: number,
    isAssistanceCancelled: number,
    warning: number,
    sessionTime?: string
  ) => {
    let className = "";
    let title = "";
    if (isAssistanceCancelled === ONE && status === null) {
      className = NEED_ASSISTANCE_CLASS;
      title = HELP_CANCELLED;
    } else if (status === ZERO) {
      className = "";
      title = MARKED_SAFE;
    } else if (status === ONE) {
      className = NEED_ASSISTANCE_CLASS;
      title = NEED_HELP;
    } else {
      if (warning === ONE) {
        className = UN_RESPONSIVE_CLASS;
        title = FIRST_WARNING_UNRESPONSIVE;
      } else if (warning === TWO) {
        className = UN_RESPONSIVE_CLASS;
        title = SECOND_WARNING_UNRESPONSIVE;
      } else {
        className = UN_RESPONSIVE_CLASS;
        title = SAFETY_TEAM_ALERTED;
      }
    }
    return (
      <>
        <td width={WIDTH_250} className={className}>
          {sessionTime &&
            dateFormat(sessionTime, DATE_FORMAT_WITH_TIME_FULL_YEAR)}{" "}
          {timeZone}
        </td>
        <td className={className}>{title}</td>
      </>
    );
  };

  // Handle to calling the disable After-hour check-in API.
  const endSession = async () => {
    const payload = {
      checkinId: parseInt(deCryptedId),
    };
    setEndSessionBtnDisable(true);
    const response: ResponseObjects = await disableAfterHourCheckIn(payload);
    if (response?.status === HTTP_RESPONSE_STATUS_200) {
      toast.success(response?.message);
      getAfterHourSessionDetails({ pageIndex: page, pageSize: perPage });
      closeDisableActive();
    } else {
      setEndSessionBtnDisable(false);
      toast.error(response?.message);
    }
    return response?.status;
  };

  // Handle toggle change to expand the note.
  const handleToggleChange = (id: number, notes: string) => {
    if (notes) {
      const updatedArray = sessionList.map((obj: any) => {
        if (obj.sessionId === id) {
          return { ...obj, toggle: !obj.toggle };
        }
        return obj;
      });
      setSessionList(updatedArray);
    }
  };

  //  Handle end session click and show confirmation popup and enable the confirmation button.
  const handleEndSessionClick = (e: any) => {
    showDisableActive(e);
    setEndSessionBtnDisable(false);
  };

  //User Session Active status and name
  function LoadSessionDetails() {
    if (detailsData) {
      return (
        <>
          <h1 className="d-flex align-items-center">
            {detailsData?.firstName} {detailsData?.lastName}{" "}
            {dateFormat(
              detailsData?.session_Start,
              DATE_FORMAT_WITHOUT_TIME_FULL_YEAR
            )}
            {detailsData?.session_Status === SESSION_ACTIVE ? (
              <>
                <Badge bg="secondary" className="afterhour">
                  {ACTIVE_SESSION}
                </Badge>
                <span
                  className="end-session"
                  style={{ textDecoration: "underline" }}
                >
                  <a
                    onClick={(e) => handleEndSessionClick(e)}
                    href={void 0}
                    role="button"
                  >
                    {afterHourSessionList?.list[0]?.response ===
                    NEED_HELP_STATUS
                      ? Strings.workAloneModeDetails.deactivateBtnTextForHelpReq
                      : Strings.workAloneModeDetails.deactivateBtnText}
                  </a>
                </span>
              </>
            ) : (
              ""
            )}
          </h1>
        </>
      );
    } else {
      return null;
    }
  }

  //User work details
  function LoadWorkDetails() {
    if (detailsData) {
      return (
        <Row>
          <Col lg="6">
            <div className="cutom-table-view">
              <div className="table-row">
                <span className="table-column">
                  {Strings.workAloneModeDetails.LengthShift}:
                </span>
                <span className="table-column">
                  {handleLengthshift(
                    detailsData?.session_Status,
                    detailsData?.session_Start,
                    detailsData?.projected_EndTime,
                    detailsData?.session_EndTime
                  )}
                </span>
              </div>
              {detailsData?.projected_EndTime && (
                <div className="table-row">
                  <span className="table-column">
                    {Strings.workAloneModeDetails.RemainingShiftTime}:
                  </span>
                  <span className="table-column">
                    {remainingTime(detailsData?.projected_EndTime)}
                  </span>
                </div>
              )}

              <div className="table-row">
                <span className="table-column">
                  {Strings.workAloneModeDetails.RiskIndicate}:
                </span>
                <span className="table-column">
                  {detailsData?.risk_Status === HIGH_RISK_STATUS
                    ? HIGH_RISK_TITLE
                    : LOW_RISK_TITLE}
                </span>
              </div>
              <div className="table-row">
                <span className="table-column">
                  {Strings.workAloneModeDetails.Work}:
                </span>
                <span className="table-column">
                  {detailsData?.floorNo} {detailsData?.building}
                </span>
              </div>
              <div className="table-row">
                <span className="table-column">
                  {Strings.workAloneModeDetails.Phone}:
                </span>
                <span className="table-column">
                  {detailsData?.phone &&
                    phoneFormat(detailsData?.phone, workaloneModeDetailsPage)}
                  {detailsData?.extension && (
                    <span>
                      {" "}
                      <b>&nbsp; Ext:</b> {detailsData?.extension}
                    </span>
                  )}
                </span>
              </div>
              <div className="table-row">
                <span className="table-column">
                  {Strings.workAloneModeDetails.Email}:
                </span>
                <span className="table-column">{detailsData?.email}</span>
              </div>
            </div>
          </Col>
          <Col lg="6" className="d-flex start-end-wrap">
            <div className="start-end-dtls">
              Start:
              <span className="d-block">
                {dateFormat(
                  detailsData?.session_Start,
                  DATE_FORMAT_WITHOUT_TIME_FULL_YEAR
                )}{" "}
                at {dateFormat(detailsData?.session_Start, DATE_FORMAT_TIME)}{" "}
                <span style={{ textTransform: "uppercase" }}>{timeZone}</span>
              </span>
            </div>
            <div className="start-end-dtls">
              projected end:
              <span className="d-block">
                {dateFormat(
                  detailsData?.projected_EndTime,
                  DATE_FORMAT_WITHOUT_TIME_FULL_YEAR
                )}{" "}
                at{" "}
                {dateFormat(detailsData?.projected_EndTime, DATE_FORMAT_TIME)}{" "}
                <span style={{ textTransform: "uppercase" }}>{timeZone}</span>
              </span>
            </div>
          </Col>
        </Row>
      );
    } else {
      return (
        <Col className="text-center">
          {Strings.workAloneModeDetails.NoDataMessage}
        </Col>
      );
    }
  }

  // Handle CSV download.
  const handleCSVDownload = async () => {
    setLoad(true);
    const response = await getWorkAloneModeDetailsForCSVDownload({
      id: deCryptedId,
    });
    if (response?.status === HTTP_RESPONSE_STATUS_200) {
      const detailsData: any = response?.data?.checkin;
      const shiftLength = handleLengthshift(
        detailsData?.session_Status,
        detailsData?.session_Start,
        detailsData?.projected_EndTime,
        detailsData?.session_EndTime
      );
      const timeRemaining = remainingTime(detailsData?.projected_EndTime);
      const updatedDetailsData: any = {
        ...response?.data?.checkin,
        shiftLength: shiftLength,
        timeRemaining: timeRemaining,
      };
      const columnHeader = [
        {
          [tableHeaders.common.timestamp]: tableHeaders.common.timestamp,
          [tableHeaders.common.response]: tableHeaders.common.status,
          [tableHeaders.common.safetyTeamNotes]:
            tableHeaders.common.safetyTeamNotes,
        },
      ];
      const responseTitle = (
        status: number,
        isAssistanceCancelled: number,
        warning: number
      ) => {
        if (isAssistanceCancelled === ONE) {
          return HELP_CANCELLED;
        } else if (status === ZERO) {
          return MARKED_SAFE;
        } else if (status === ONE) {
          return NEED_HELP;
        } else {
          if (warning === ONE) {
            return FIRST_WARNING_UNRESPONSIVE;
          } else if (warning === TWO) {
            return SECOND_WARNING_UNRESPONSIVE;
          } else {
            return SAFETY_TEAM_ALERTED;
          }
        }
      };

      const sessionList: any = [];
      if (response?.data?.sessions?.length > ZERO) {
        response?.data?.sessions?.map((data: any) => {
          sessionList.push({
            [tableHeaders.common.timestamp]:
              dateConversion(
                data?.sessionTime ? data.sessionTime : data?.createdOn,
                DATE_FORMAT_WITH_TIME_PREFIX
              ) +
              " " +
              timeZone,
            [tableHeaders.common.response]: responseTitle(
              data?.response,
              data?.isAssistanceCancelled,
              data?.warning
            ),
            [tableHeaders.common.safetyTeamNotes]: data?.notes,
          });
        });
      }

      downloadCSVForChkInDetails(
        updatedDetailsData,
        sessionList?.length > ZERO ? [...columnHeader, ...sessionList] : [],
        csvDownload.workaloneModeDetails,
        timeZone
      );
    }
    setLoad(false);
  };

  return (
    <>
      {initialLoad ? (
        <Col
          style={{ minHeight: "100vh", paddingTop: 200 }}
          className=" text-center mt-5"
        >
          <output className="spinner-border"></output>
        </Col>
      ) : (
        <>
          <Row className="tilte-wrapper">
            <Col md="8">
              <div
                className="back"
                onClick={() => {
                  navigate(pageURLs.workAloneModeIn);
                }}
              >
                <ICBack />
              </div>
              <LoadSessionDetails />
            </Col>
            <Col md="4" className="text-end">
              <Button
                disabled={!afterHourDetails}
                onClick={() => handleCSVDownload()}
              >
                {load ? (
                  <output className="spinner-border sm"></output>
                ) : (
                  Strings.common.dowloadCSV
                )}
              </Button>
            </Col>
          </Row>
          <div className="content-wrapper">
            <LoadWorkDetails />
          </div>
          <Table responsive>
            <TableHeader columns={columns} />
            <tbody>
              {sessionList &&
                sessionList.length > ZERO &&
                sessionList.map((items: any) => (
                  <tr key={items.sessionId}>
                    {statusTitlesAndDate(
                      items?.response,
                      items?.isAssistanceCancelled,
                      items?.warning,
                      items?.responseTime
                        ? items?.responseTime
                        : items?.sessionTime
                    )}

                    <AddEditNote
                      items={items}
                      id={items?.sessionId}
                      handleSave={updateNote}
                    />
                    <td
                      className="toggle text-center"
                      onClick={() => {
                        handleToggleChange(items.sessionId, items?.notes);
                      }}
                    >
                      <span
                        className={
                          items?.notes === null
                            ? "disabled"
                            : !items.toggle
                              ? "tableExpand"
                              : "tableCollapse"
                        }
                      >
                        <ICTableExpand />
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <DeleteConfModal
            onHide={closeDisableActive}
            show={showDisable}
            title={Strings.workAloneModeDetails.EndSessionModalTitle}
            desc={Strings.workAloneModeDetails.EndSessionModalInfo}
            handleDelete={endSession}
            isDisabled={endSessionBtnDisable}
          />
          {!load && sessionList && sessionList.length === ZERO ? (
            <Col className="text-center no-record">
              {Strings.workAloneModeDetails.NoDataMessage}
            </Col>
          ) : (
            ""
          )}
          {load && (
            <Col className="text-center no-record mt-5">
              <output className="spinner-border"></output>
            </Col>
          )}
          {totalRecord !== ZERO && (
            <Paginate
              totalRecords={totalRecord}
              currentPage={page}
              perPage={perPage}
              onClick={pageChange}
              onShowClick={perPageChange}
            />
          )}
        </>
      )}
    </>
  );
};

export default WorkAloneModeDetails;
