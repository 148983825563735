/**
 * @file   src\components\BulkUserUpload.tsx
 * @brief  User bulk upload via CSV file.
 * @date   May, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import { Modal, Button } from "react-bootstrap";
import Strings from "../assets/strings/Strings.json";
import SLFileUpload from "../components/SLFileUpload";
import { SAMPLE_CSV_FILE_PATH } from "../constants/endPoints";

const BulkUserUploadModal = (props: any) => {
  const {
    handleBulkUserModal,
    show,
    isDisable,
    uploadFile,
    fileName,
    handleConfirmUpload,
    errorMessage,
  } = props;
  const ACCEPTED_FORMATS = ".csv";
  const templateFileName = "UserSampleTemplate.csv";

  // For download bulk user update modal template
  const handleDownload = () => {
    fetch(SAMPLE_CSV_FILE_PATH)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.download = templateFileName;
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error fetching the file:", error);
      });
  };
  return (
    <Modal
      show={show}
      onHide={() => handleBulkUserModal(false)}
      backdrop="static"
      centered
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{Strings.ManageUser.BulkUpload.Title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="alert-info text-center">
        <p>
          Upload users in bulk via .csv file(Max size 2MB). <br />
          <a role="button" href={void 0} onClick={() => handleDownload()}>
            Click here
          </a>{" "}
          to download a template.
        </p>
        <SLFileUpload
          accept={ACCEPTED_FORMATS}
          onChange={uploadFile}
          fileName={fileName}
          errorMessage={errorMessage}
        />
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button variant="secondary" onClick={() => handleBulkUserModal(false)}>
          {Strings.ManageUser.RemoveUsers.cancelBtn}
        </Button>
        <Button
          variant="primary"
          disabled={isDisable}
          onClick={() => handleConfirmUpload()}
        >
          {Strings.ManageUser.RemoveUsers.confirmUploadBtn}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BulkUserUploadModal;
