/**
 *@file SLDatePicker.tsx
 *@brief Reusable Datepicker Component
 *@date Oct, 2024
 *@author ZCO Engineer
 *@copyright (c) 2024, ZCO
 */
import "./SLDatePicker.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const SLDatePicker = (props: any) => {
  return (
    <>
      <div className="sl-datepicker ">
        {props.label && <label>{props.label}</label>}
        <DatePicker
          className={props?.errorMessage ? "form-error" : ""}
          onKeyDown={props.onKeyDown}
          selected={props?.selected}
          minDate={props?.minDate}
          maxDate={props?.maxDate}
          onChange={props?.onChange}
          placeholderText={props?.placeholder}
        />
        {props.errorMessage && (
          <div className="error-msg">{props.errorMessage}</div>
        )}
      </div>
    </>
  );
};

export default SLDatePicker;
