const ICClose = () => {
  return (
    <svg id="close" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <path id="Line_4" data-name="Line 4" d="M5,5.75a.748.748,0,0,1-.53-.22l-5-5A.75.75,0,0,1-.53-.53.75.75,0,0,1,.53-.53l5,5A.75.75,0,0,1,5,5.75Z" transform="translate(5.5 5.5)" />
      <path id="Line_5" data-name="Line 5" d="M0,5.75a.748.748,0,0,1-.53-.22.75.75,0,0,1,0-1.061l5-5a.75.75,0,0,1,1.061,0A.75.75,0,0,1,5.53.53l-5,5A.748.748,0,0,1,0,5.75Z" transform="translate(5.5 5.5)" />
      <path id="Ellipse_10" data-name="Ellipse 10" d="M8,1.5A6.5,6.5,0,1,0,14.5,8,6.507,6.507,0,0,0,8,1.5M8,0A8,8,0,1,1,0,8,8,8,0,0,1,8,0Z" />
    </svg>
  );
};
export default ICClose;
