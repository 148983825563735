/**
 * @file   src\containers\Organization.tsx
 * @brief  Organization page.
 * @date   Feb, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import SLInput from "../components/SLInput";
import Strings from "../assets/strings/Strings.json";
import { Button, Col, Form, Row } from "react-bootstrap";
import SLSelect from "../components/SLSelect";
import { useEffect, useState } from "react";
import {
  FormError,
  OrgForm,
  UpdateOrgRequestPayload,
} from "../interfaces/Organization";
import {
  CHK_IN_INTERVALS,
  HTTP_RESPONSE_STATUS_200,
  MAX_LENGTH_100,
  MAX_LENGTH_12,
  MAX_LENGTH_255,
  USER_INFO_KEY,
  ZERO,
} from "../constants/common";
import {
  ENTER_CITY,
  ENTER_MAIN_ADDRESS,
  ENTER_ORG_NAME,
  ENTER_ZIP,
  INVALID_CONTENT,
  SELECT_HIGH_RISK_INTERVALS,
  SELECT_LOW_RISK_INTERVALS,
  SELECT_STATE,
  SELECT_TIMEZONE,
} from "../constants/validationMessages";
import { convertToNumber, getLocalStorage } from "../helpers/common";
import { ResponseObjects } from "../interfaces/AxiosResponse";
import { useAppDispatch, useAppSelector } from "../Redux/hooks";
import { updateOrganization } from "../services/organization";
import { toast } from "react-toastify";
import {
  fetchOrganizationDetails,
  fetchStateLists,
  fetchTimeZoneList,
} from "../Redux/actions/organization";
import { fetchBuildingList } from "../Redux/actions/manageBuildings";
import { checkIsHtml, numberValidation } from "../helpers/validations";
import { useNavigate } from "react-router-dom";
import { pageURLs } from "../constants/pageURLs";
import ManageBuildings from "../components/ManageBuildings";
import { AddUpdteBuilding, DeleteBuilding } from "../interfaces/Buildings";
import {
  addBuilding,
  deleteBuilding,
  updateBuilding,
} from "../services/manageBuildingService";
import ICSafetyOfficer from "../assets/img/icons/SafetyOfficer";
import ICRallyPoints from "../assets/img/icons/RallyPoints";
import ICImportantLinks from "../assets/img/icons/ImportantLinks";

const Organization = () => {
  const navigate = useNavigate();
  //Initialise form errors
  const initialErrors = {
    orgNameError: "",
    mainAddressError: "",
    address2Error: "",
    cityError: "",
    stateError: "",
    zipError: "",
    imageError: "",
    highRiskChkInError: "",
    lowRiskChkInError: "",
    timeZoneError: "",
  };

  //Declare  constant values

  //Initialise state variables

  const STRING_ZERO = "0";
  const [formData, setFormData] = useState<OrgForm>();
  const [formError, setFormError] = useState<FormError>(initialErrors);
  const [buildingList, setBuildingList] = useState<any>([]);

  //Oragnisation details from redux store

  //Oragnisation details loading status from redux store
  const { loading, orgDetails, timeZoneList } = useAppSelector(
    (state: any) => state?.organization
  );

  //State list  from redux store
  const states = useAppSelector(
    (state: any) => state?.organization?.stateLists
  );

  //Building list and loading state  from redux store
  const list = useAppSelector((state: any) => state?.building?.list);
  const buildingListIsLoading = useAppSelector(
    (state: any) => state?.building?.loading
  );

  //Update building list into local state
  useEffect(() => {
    setBuildingList(list);
  }, [list]);
  const dispatch = useAppDispatch();

  //This will trigger to fetch the organisation details from DB via API call
  useEffect(() => {
    const userInfoFromLS = getLocalStorage(USER_INFO_KEY);
    const orgId: number = userInfoFromLS?.orgId;
    dispatch(
      fetchOrganizationDetails({
        id: orgId,
      })
    );
    dispatch(fetchStateLists());
    dispatch(fetchTimeZoneList());
    getBuildingList();
  }, []);

  //Function for fetching building list
  const getBuildingList = async (searchText?: string) => {
    dispatch(fetchBuildingList({ searchText: searchText }));
  };

  //Set the organisation details into form data to fill the textfields.
  useEffect(() => {
    const isNotify = orgDetails?.isLowRisk_Notify === ZERO ? true : false;

    setFormData((form: OrgForm) => ({
      ...form,
      orgId: orgDetails?.orgId,
      orgName: orgDetails?.orgName,
      mainAddress: orgDetails?.address_1,
      address2: orgDetails?.address_2,
      city: orgDetails?.city,
      state: orgDetails?.state,
      zip: orgDetails?.zip,
      highRiskChkIn: orgDetails?.checkin_HighRisk,
      lowRiskChkIn: orgDetails?.checkin_LowRisk,
      isLowRiskNotify: isNotify,
      logo: orgDetails?.logo,
      timeZoneId: orgDetails?.timezoneId,
    }));
  }, [orgDetails]);

  //This will trigger in text box changes and update into respective state objects.
  const onTextBoxChange = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    const currentId = e.currentTarget.id;
    setFormData((form: OrgForm) => ({
      ...form,
      [currentId]:
        currentId === "zip"
          ? !numberValidation.test(value)
            ? value
            : formData?.zip
          : value,
    }));
  };

  //Handle final submit
  const handleSaveOrgData = async () => {
    setFormError(initialErrors);
    const isValid = validateFormData();

    if (isValid) {
      const payload: UpdateOrgRequestPayload = {
        orgId: formData.orgId,
        organizationName: formData.orgName,
        address1: formData.mainAddress,
        address2: formData.address2,
        city: formData.city,
        state: formData.state,
        zip: formData.zip,
        checkinHighRisk: convertToNumber(formData.highRiskChkIn.toString()),
        checkinLowRisk: convertToNumber(formData.lowRiskChkIn.toString()),
        isLowRiskNotify: !formData.isLowRiskNotify,
        timezoneId: convertToNumber(formData?.timeZoneId?.toString()) || null,
        logo: "",
        oldLogo: "",
      };

      const response: ResponseObjects = await updateOrganization(payload);
      if (response?.status === HTTP_RESPONSE_STATUS_200) {
        toast.success(response?.message);
      } else {
        toast.error(response?.message);
      }
    }
  };

  //Handle Save building Data
  const handleSaveBuilding = async (
    payload: AddUpdteBuilding,
    searchText?: string
  ) => {
    const response: ResponseObjects = payload?.id
      ? await updateBuilding(payload)
      : await addBuilding(payload);
    if (response?.status === HTTP_RESPONSE_STATUS_200) {
      getBuildingList(searchText);
      toast.success(response?.message);
      return { status: HTTP_RESPONSE_STATUS_200 };
    } else {
      toast.error(response?.message);
      return null;
    }
  };

  //Validate each form data and throw errors if anything invalid
  const validateFormData = () => {
    setFormError((error) => ({
      ...error,
      orgNameError: null,
      mainAddressError: null,
      address2Error: null,
      cityError: null,
      stateError: null,
      zipError: null,
      highRiskChkInError: null,
      lowRiskChkInError: null,
    }));
    let isValid = true;
    const orgName = formData?.orgName?.trim();
    const mainAddress = formData?.mainAddress?.trim();
    const address2 = formData?.address2?.trim();
    const city = formData?.city?.trim();
    const state = formData?.state;
    const zip = formData?.zip;
    const highRiskChkIn = formData?.highRiskChkIn?.toString();
    const lowRiskChkIn = formData?.lowRiskChkIn?.toString();
    const timeZone = formData?.timeZoneId;

    if (address2) {
      const isHtml = checkIsHtml(address2);
      if (!isHtml) {
        setFormError((error) => ({
          ...error,
          address2Error: INVALID_CONTENT,
        }));
        isValid = false;
      }
    }

    if (!orgName) {
      setFormError((error) => ({
        ...error,
        orgNameError: ENTER_ORG_NAME,
      }));
      isValid = false;
    } else {
      const isHtml = checkIsHtml(orgName);
      if (!isHtml) {
        setFormError((error) => ({
          ...error,
          orgNameError: INVALID_CONTENT,
        }));
        isValid = false;
      }
    }
    if (!mainAddress) {
      setFormError((error) => ({
        ...error,
        mainAddressError: ENTER_MAIN_ADDRESS,
      }));
      isValid = false;
    } else {
      const isHtml = checkIsHtml(mainAddress);
      if (!isHtml) {
        setFormError((error) => ({
          ...error,
          mainAddressError: INVALID_CONTENT,
        }));
        isValid = false;
      }
    }

    if (!city) {
      setFormError((error) => ({
        ...error,
        cityError: ENTER_CITY,
      }));
      isValid = false;
    } else {
      const isHtml = checkIsHtml(city);
      if (!isHtml) {
        setFormError((error) => ({
          ...error,
          cityError: INVALID_CONTENT,
        }));
        isValid = false;
      }
    }

    if (
      !state ||
      state === STRING_ZERO ||
      !states?.find((e: any) => e.st_Code === state)
    ) {
      setFormError((error) => ({
        ...error,
        stateError: SELECT_STATE,
      }));
      isValid = false;
    }
    if (!zip) {
      setFormError((error) => ({
        ...error,
        zipError: ENTER_ZIP,
      }));
      isValid = false;
    }
    if (
      !highRiskChkIn ||
      highRiskChkIn === STRING_ZERO ||
      !CHK_IN_INTERVALS.find((e) => e.id.toString() === highRiskChkIn)
    ) {
      setFormError((error) => ({
        ...error,
        highRiskChkInError: SELECT_HIGH_RISK_INTERVALS,
      }));
      isValid = false;
    }

    if (!formData?.isLowRiskNotify) {
      if (
        !lowRiskChkIn ||
        lowRiskChkIn === STRING_ZERO ||
        !CHK_IN_INTERVALS.find((e) => e.id.toString() === lowRiskChkIn)
      ) {
        setFormError((error) => ({
          ...error,
          lowRiskChkInError: SELECT_LOW_RISK_INTERVALS,
        }));
        isValid = false;
      }
    }

    if (!timeZone || timeZone?.toString() === "0") {
      setFormError((error) => ({
        ...error,
        timeZoneError: SELECT_TIMEZONE,
      }));
      isValid = false;
    }

    return isValid;
  };

  //Handle state drodown selection and update the value into the state
  const handleStateSelection = (selectedItem: string) => {
    setFormData((form: OrgForm) => ({
      ...form,
      state: selectedItem,
    }));
  };

  //Handle TimeZone Change
  const handleTimeZoneSelection = (selectedItem: number) => {
    setFormData((form: OrgForm) => ({
      ...form,
      timeZoneId: selectedItem,
    }));
  };

  //Handle high risk time interval drodown selection and update the value into the state
  const handleHighRiskSelection = (selectedItem: number) => {
    setFormData((form: OrgForm) => ({
      ...form,
      highRiskChkIn: selectedItem,
    }));
  };

  //Handle low risk time interval drodown selection and update the value into the state
  const handleLowRiskSelection = (selectedItem: number) => {
    setFormData((form: OrgForm) => ({
      ...form,
      lowRiskChkIn: selectedItem,
    }));
  };

  //Handle checkbox toggling  and update the value into the state
  const onSwitchLowRiskNotify = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checkedStatus = e.currentTarget.checked;
    setFormData((form: OrgForm) => ({
      ...form,
      isLowRiskNotify: checkedStatus === true ? true : false,
    }));

    if (checkedStatus) {
      setFormError((error) => ({
        ...error,
        lowRiskChkInError: null,
      }));
    }
  };

  //Handle Building Delete
  const handleDelete = async (payload: DeleteBuilding) => {
    if (payload?.id) {
      const response: ResponseObjects = await deleteBuilding(payload);
      if (response?.status === HTTP_RESPONSE_STATUS_200) {
        toast.success(response?.message);
        const copyList: any = [...buildingList];
        const updatedList = copyList?.filter(function (ls: { id: number }) {
          return ls.id !== payload?.id;
        });
        setBuildingList(updatedList);
        return { status: HTTP_RESPONSE_STATUS_200 };
      } else {
        toast.error(response?.message);
        return null;
      }
    }
  };

  //Building search
  const handleBuildingSearch = (payload: { searchText: string }) => {
    getBuildingList(payload?.searchText);
  };

  return (
    <>
      {!loading && orgDetails ? (
        <>
          <Row className="tilte-wrapper">
            <Col xl="12">
              <h1>
                <span className="text-uppercase">
                  {Strings.Common.InnerSubTitle}
                </span>
                <br />
                {Strings.Organization.Title}
              </h1>
            </Col>
          </Row>
          <Row className="action-wrapper d-flex align-items-center mb-3">
            <Col md="6" xl="4">
              <h3 className="organization">
                {Strings.Organization.orgInfoTitle}
              </h3>
            </Col>
          </Row>
          <div className="content-wrapper dashboard welcome">
            <Row>
              <Col xl="4" md="12">
                <SLInput
                  id="orgName"
                  label={Strings.Organization.orgName}
                  type="text"
                  value={formData?.orgName}
                  onChange={onTextBoxChange}
                  errorMessage={formError?.orgNameError}
                  maxLength={MAX_LENGTH_100}
                />
              </Col>
              <Col xl="4" md="12">
                <SLInput
                  id="mainAddress"
                  label={Strings.Organization.orgAddress}
                  type="text"
                  value={formData?.mainAddress}
                  onChange={onTextBoxChange}
                  errorMessage={formError?.mainAddressError}
                  maxLength={MAX_LENGTH_255}
                />
              </Col>
            </Row>
            <Row>
              <Col xl="4" md="12">
                <SLInput
                  id="address2"
                  label={Strings.Organization.orgAddress2}
                  type="text"
                  value={formData?.address2}
                  onChange={onTextBoxChange}
                  maxLength={MAX_LENGTH_255}
                />
              </Col>
              <Col xl="4" md="12">
                <SLInput
                  id="city"
                  label={Strings.Organization.city}
                  type="text"
                  value={formData?.city}
                  onChange={onTextBoxChange}
                  errorMessage={formError?.cityError}
                  maxLength={MAX_LENGTH_255}
                />
              </Col>
            </Row>
            <Row>
              <Col xl="8" md="12">
                <Row>
                  <Col xl="4" md="12">
                    <SLSelect
                      label={Strings.Organization.state}
                      list={states}
                      handleSelection={handleStateSelection}
                      selectedValue={formData?.state}
                      errorMessage={formError?.stateError}
                      disabled={false}
                    />
                  </Col>
                  <Col xl="4" md="12">
                    <SLInput
                      id="zip"
                      label={Strings.Organization.zip}
                      type="text"
                      value={formData?.zip || ""}
                      onChange={onTextBoxChange}
                      errorMessage={formError?.zipError}
                      maxLength={MAX_LENGTH_12}
                    />
                  </Col>
                  <Col xl="4" md="12">
                    <SLSelect
                      label={Strings.Organization.timeZone}
                      DefaultItemText="Select Time zone"
                      list={timeZoneList}
                      handleSelection={handleTimeZoneSelection}
                      selectedValue={formData?.timeZoneId}
                      errorMessage={formError?.timeZoneError}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <Row className="action-wrapper d-flex align-items-center mb-3">
            <Col md="6" xl="4">
              <h3 className="organization">
                {Strings.Organization.chkInIntervalTitle}
              </h3>
            </Col>
          </Row>
          <div className="content-wrapper dashboard welcome">
            <ul>
              <li>{Strings.Organization.CheckInInfo}</li>
            </ul>
            <Row className="mt-4">
              <Col xl="3" md="12">
                <div className="d-flex checkin-intervals">
                  <SLSelect
                    label={Strings.Organization.minutes}
                    list={CHK_IN_INTERVALS}
                    handleSelection={handleHighRiskSelection}
                    selectedValue={formData?.highRiskChkIn}
                    errorMessage={formError?.highRiskChkInError}
                    disabled={false}
                  />
                  <div>
                    <p>
                      <span>{Strings.Organization.HighRiskTitle}</span>
                      <br />
                      {Strings.Organization.HighRiskInfo}
                    </p>
                  </div>
                </div>
              </Col>
              <Col xl="6" md="12">
                <div className="d-flex checkin-intervals">
                  <SLSelect
                    label={Strings.Organization.minutes}
                    list={CHK_IN_INTERVALS}
                    handleSelection={handleLowRiskSelection}
                    selectedValue={formData?.lowRiskChkIn}
                    errorMessage={formError?.lowRiskChkInError}
                    disabled={
                      formData?.isLowRiskNotify === false ? false : true
                    }
                  />
                  <div>
                    <p>
                      <span>{Strings.Organization.LowRiskTitle}</span>
                      <br />
                      {Strings.Organization.LowRiskInfo}
                      <Form.Check
                        className="mt-2"
                        type="switch"
                        id="custom-switch"
                        checked={formData?.isLowRiskNotify}
                        onChange={onSwitchLowRiskNotify}
                        label={Strings.Organization.TurnOff}
                      />
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <Row className="btn-wrap mt-3 mb-4">
            <Col xl="8" className="text-end">
              <Button
                variant="primary"
                onClick={() => {
                  handleSaveOrgData();
                }}
              >
                {Strings.Organization.saveBtn}
              </Button>
            </Col>
          </Row>
          <Row className="action-wrapper d-flex align-items-center mb-3">
            <h3 className="organization">{Strings.buildings.mainTitle}</h3>
          </Row>
          <div className="content-wrapper dashboard welcome buildings">
            <ul className="mb-4">
              <li>{Strings.buildings.note1}</li>
              <li>
                {
                  'If you have fully remote employees, add a location titled "100% Remote."'
                }
              </li>
              <li>{Strings.buildings.note3}</li>
            </ul>
            <ManageBuildings
              handleSaveBuilding={handleSaveBuilding}
              buildingList={buildingList}
              handleDelete={handleDelete}
              buildingListIsLoading={buildingListIsLoading}
              states={states}
              handleBuildingSearch={handleBuildingSearch}
            />
          </div>
        </>
      ) : (
        ""
      )}
      {loading ? (
        <Col className="text-center no-record mt-5">
          <output className="spinner-border"></output>
        </Col>
      ) : (
        <>
          {" "}
          <Col className="action-wrapper d-flex align-items-center mb-3">
            <h3 className="organization">
              {Strings.Organization.AppDisplayTitle}
            </h3>
          </Col>
          <div className="content-wrapper dashboard app-display">
            <Button
              variant="primary"
              onClick={() => navigate(pageURLs.safetyOfficers)}
            >
              <ICSafetyOfficer />
              Safety Team
            </Button>
            <Button
              variant="primary"
              onClick={() => navigate(pageURLs.rallyPoints)}
            >
              <ICRallyPoints />
              {Strings.SideBar.rallyPoints}
            </Button>
            <Button
              variant="primary"
              onClick={() => navigate(pageURLs.importantlinks)}
            >
              <ICImportantLinks />
              Important Links
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default Organization;
