/**
 * @file   src\containers\EmergencycheckIn.tsx
 * @brief  Emergency checkin listing page.
 * @date   Feb, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import Strings from "../assets/strings/Strings.json";
import { Accordion, Button, Col, Row } from "react-bootstrap";
import { useAppSelector, useAppDispatch } from "../Redux/hooks";
import { useState, useEffect } from "react";
import {
  EMERGENCY_CHK_INS,
  ONE,
  PAGE_SIZE_20,
  ZERO,
  csvDownload,
} from "../constants/common";
import {
  downloadCSV,
  encryptText,
  getTimeZone,
  pushAndFormatCSVArray,
} from "../helpers/common";
import Paginate from "../components/common/Paginate";
import Search from "../components/common/Search";
import SLCheckIn from "../components/SLCheckIn";
import { fetchEmergencyListing } from "../Redux/actions/emergencyCheckIn";
import { SearchRequest } from "../interfaces/Search";
import { pageURLs } from "../constants/pageURLs";
import { useNavigate } from "react-router-dom";

const EmergencyCheckIn = () => {
  const [emergencyList, setEmergencyList] = useState<any>([]);
  const [csvData, setCsvData] = useState<any>([]);
  const [page, setPage] = useState<number>(ONE);
  const [perPage, setPerPage] = useState<number>(PAGE_SIZE_20);
  const [totalRecord, setTotalRecord] = useState<number>(ZERO);
  const [searchText, setSearchText] = useState<string>("");
  const timeZone = getTimeZone();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const emergencyListFormStore = useAppSelector(
    (state: any) => state.emergency?.emergencyList?.list
  );
  const emergencyListLoading = useAppSelector(
    (state: any) => state.emergency?.emergencyList?.loading
  );

  // Handle to calling the emergency list API with the dependency of pagination changes
  useEffect(() => {
    dispatch(
      fetchEmergencyListing({
        pageNo: page,
        pageSize: perPage,
        searchText: searchText,
      })
    );
  }, [page, perPage]);

  // Handle response from the emergency list api call and set into local state.
  useEffect(() => {
    if (!emergencyListLoading) {
      loadEmergencyChkInList();
    }
  }, [emergencyListFormStore]);

  // Handle calling the emergency list api API with a search payload
  const onSearchClick = (payload: SearchRequest) => {
    dispatch(fetchEmergencyListing(payload));
  };

  // Handle setting the response from the emergency list  API call into state.
  const loadEmergencyChkInList = async () => {
    const list = emergencyListFormStore?.list;
    if (list) {
      const csvArry: [] = pushAndFormatCSVArray(
        list,
        csvDownload.emergencyChkIn,
        timeZone
      );
      setTotalRecord(emergencyListFormStore?.totalRecords);
      setCsvData(csvArry);
      setEmergencyList(list);
    } else setTotalRecord(ZERO);
  };

  // page change and state change
  const pageChange = (page: any) => {
    if (page) {
      setPage(page);
    }
  };
  const perPageChange = (page: any) => {
    if (page) {
      setPage(1);
      setPerPage(page);
    }
  };

  // handle navigation to emergency details page
  const handleNavigationDetails = (id: number) => {
    navigate(
      `${pageURLs.emergencyCheckInDetails}/${encryptText(id?.toString())}`
    );
  };
  return (
    <>
      <Row className="tilte-wrapper">
        <Col lg="12">
          <h1>
            <span className="text-uppercase">
              {Strings.Common.InnerSubTitle}
            </span>
            <br />
            {Strings.EmergencyCheckIn.Title}
          </h1>
        </Col>
      </Row>
      <Accordion defaultActiveKey={["0"]} alwaysOpen>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            {Strings.EmergencyCheckIn.Instructions}
          </Accordion.Header>
          <Accordion.Body className="instructions">
            <ul>
              <li>
                Login to the Spotlight Safety <span>app</span> using the
                administrator account.
              </li>
              <li>
                Click <span>“Enable Emergency Check-In System”</span> at the
                bottom of the screen and follow the prompts.
              </li>
              <li>
                Once activated, the system will send a push{" "}
                <span>notification</span> to all employees asking them to open
                the app and report in.
              </li>
              <li>
                If an employee clicks “I need help”, they will be prompted to
                send a <span>message</span> to safety officers. These messages
                will be delivered via email and push notification.
              </li>
              <li>
                All employee responses are available under the{" "}
                <span>“Status”</span> tab. You can also use the{" "}
                <span>“Updates”</span> tab to keep employees informed.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <h2>{Strings.EmergencyCheckIn.chkInLog}</h2>
      <Row className="action-wrapper">
        <Col md="6" xl="4">
          <Search
            onClick={onSearchClick}
            pageSize={perPage}
            setPage={setPage}
            page={page}
            searchText={searchText}
            setSearchText={setSearchText}
          />
        </Col>
        <Col md="6" xl="8" className="text-end">
          <Button
            disabled={emergencyList?.length === 0}
            onClick={() => downloadCSV(csvData, csvDownload.emergencyChkIn)}
          >
            {Strings.common.dowloadCSV}
          </Button>
        </Col>
      </Row>
      {emergencyList &&
        emergencyList.length > ZERO &&
        emergencyList.map((items: any, index: number) => (
          <SLCheckIn
            activeClass="emergency-active"
            key={index}
            itemKey={items?.checkin_Id}
            data={items}
            navigate={() => handleNavigationDetails(items.checkin_Id)}
            page={EMERGENCY_CHK_INS}
            timeZone={timeZone}
          />
        ))}
      {!emergencyListLoading &&
      emergencyList &&
      emergencyList.length == ZERO ? (
        <Col className="text-center mt-3 mb-3">
          {Strings.EmergencyCheckIn.NoDataMessage}
        </Col>
      ) : (
        ""
      )}
      {emergencyListLoading && (
        <Col className="text-center no-record mt-5">
          <output className="spinner-border"></output>
        </Col>
      )}
      {totalRecord !== 0 && (
        <Paginate
          totalRecords={totalRecord}
          currentPage={page}
          perPage={perPage}
          onClick={pageChange}
          onShowClick={perPageChange}
        />
      )}
    </>
  );
};

export default EmergencyCheckIn;
