export const USER_INFO_KEY = "userInfo";
export const NOTE = "note";
export const USER_NOT_ACTIVE = 0;
export const USER_NOT_ADMIN = 0;
export const USER_ADMIN = 1;
export const STATUS_0 = 0;
export const STATUS_1 = 1;
export const PHONE = "phone";
export const EXTENSION = "extension";
export const PASSWORD = "password";
export const CURRENT_PASSWORD = "currentPassword";
export const CONFIRM_PASSWORD = "confirmPassword";
export const NEW_PASSWORD = "newPassword";
export const EDIT = "edit";
export const EMAIL = "email";
export const EDITED = "edited";
export const DATE_FORMAT_WITH_TIME_PREFIX = "MM-DD-YY T:hh:mm:ss A";
export const DATE_FORMAT_WITH_TIME = "MM/DD/YY hh:mm:ss A";
export const DATE_FORMAT_WITH_TIME_FULL_YEAR = "MM/DD/YYYY hh:mm:ss A";
export const DATE_FORMAT_WITH_TIME_FULL_YEAR_24_HOUR = "MM/DD/YYYY HH:mm:ss";
export const DATE_FORMAT_WITHOUT_TIME = "MM/DD/YY";
export const DATE_FORMAT_YYYY_MM_DD_HH_MM = "YYYY/MM/DD hh:mm:ss A";
export const DATE_FORMAT_YYYY_MM_DD_HH_MM_24_HOUR = "YYYY/MM/DD HH:mm:ss";
export const DATE_FORMAT_WITHOUT_TIME_FULL_YEAR = "MM/DD/YYYY";
export const DATE_FORMAT_TIME = "hh:mm:ss A";
export const DATE_FORMAT_TIME_HH_MM = "hh:mm A";
export const DATE_FORMAT_TIME_ONLY_HH_MM = "HH:mm";
export const APPLE_STORE =
  "https://apps.apple.com/app/spotlight-safety/id6477921684/";
export const ANDROID_STORE =
  "https://play.google.com/store/apps/details?id=com.spotlightinc.spotlightsafety";
export const PASSWORD_CREATION_SUCCESS_REDIRECT_URL =
  "https://spotlightsafetyapp.com/success/";
export const HTTP_RESPONSE_STATUS_200 = 200;
export const HTTP_RESPONSE_STATUS_406 = 406;
export const HTTP_RESPONSE_STATUS_500 = 500;
export const HTTP_RESPONSE_STATUS_403 = 403;
export const MAX_LENGTH_45 = 45;
export const MAX_LENGTH_255 = 255;
export const MAX_LENGTH_200 = 200;
export const MAX_LENGTH_100 = 100;
export const MAX_LENGTH_12 = 12;
export const MAX_LENGTH_3 = 3;
export const MAX_LENGTH_2000 = 2000;
export const MAX_LENGTH_500 = 500;
export const MAX_LENGTH_50 = 50;
export const MAX_LENGTH_25 = 25;
export const MAX_LENGTH_20 = 20;
export const MAX_LENGTH_8 = 8;
export const PAGE_SIZE_20 = 20;
export const PAGE_SIZE_50 = 50;
export const PAGE_SIZE_100 = 100;
export const HTTP_POST = "POST";
export const HTTP_GET = "GET";
export const HTTP_PUT = "PUT";
export const WIDTH_175 = 175;
export const WIDTH_100 = 100;
export const WIDTH_200 = 200;
export const WIDTH_220 = 220;
export const WIDTH_250 = 250;
export const WIDTH_190 = 190;
export const HEIGHT_400 = 400;
export const ENTER_KEY_CODE = 13;
export const API_TIMEOUT = 30000; // In seconds
export const HTTP_STATUS_401 = 401;
export const HTTP_STATUS_498 = 498;
export const HTTP_STATUS_429 = 429;
export const CONTENT_TYPE_FORM_DATA = "multipart/form-data";
export const EMPTY_STRING = "";
export const ZERO = 0;
export const ONE = 1;
export const TWO = 2;
export const THREE = 3;
export const SIX = 6;
export const TEN = 10;
export const ACTIVE = "ACTIVE";
export const NEED_HELP_STATUS = 1;
export const USER_ACTIVE = 1;
export const SESSION_ACTIVE = 1;
export const ALERT_DANGER = "danger";
export const ALERT_SUCCESS = "success";
export const LIST_SAFETY_PAGE = "Safety";
export const HIGH_RISK_TITLE = "High Risk";
export const LOW_RISK_TITLE = "Low Risk";
export const HIGH_RISK_STATUS = 1;
export const workaloneModeDetailsPage = "workaloneModeDetailsPage";

export const csvDownload = {
  afterHour: "WorkAloneMode",
  anonymousSuggestion: "AnonymousSuggestions",
  emergencyChkIn: "EmergencyCheckIns",
  emergencyChkInDetails: "IndividualEmergencyCheckIn",
  workaloneModeDetails: "IndividualWorkAloneLog",
  incidentReport: "IncidentReports",
  users: "Users",
};
export const MAX_FILE_SIZE = 5000000; // In bytes
export const MAX_FILE_SIZE_2MB = 2000000; // In bytes
export const EMERGENCY_CHK_INS = "EMERGENCY_CHK_INS";
export const UNRESPONSIVE = "Unresponsive";
export const SAFETY_TEAM_ALERTED = "Safety Team Alerted";
export const FIRST_WARNING_UNRESPONSIVE = "First Check-in : Unresponsive";
export const SECOND_WARNING_UNRESPONSIVE = "Second Check-in : Unresponsive";
export const HELP_CANCELLED = "Help Cancelled";
export const MARKED_SAFE = "Marked Safe";
export const NEED_HELP = "Need Help";
export const ACCEPTED_IMAGE_FORMATS = "image/png, image/jpeg, image/jpeg";
export const SUPPORTED_FORMATS_ARRAY = ["image/jpg", "image/jpeg", "image/png"];
export const API_CALL_INTERVAL = 15000; //In seconds
export const noSubject = "";
export const noMessage = "";
export const noEmail = "";
export const YES_TITLE = "Yes";
export const NO_TITLE = "No";

export const TimeZoneAbbreviations = [
  {
    GMT: "GMT+5:30",
    zone: "IST",
  },
  {
    GMT: " GMT-5",
    zone: "EST",
  },
  {
    GMT: "GMT-6",
    zone: "CST",
  },
  {
    GMT: "GMT-7",
    zone: "MST",
  },
  {
    GMT: "GMT-8",
    zone: "PST",
  },
  {
    GMT: "GMT-9",
    zone: "AKST",
  },
  {
    GMT: "GMT-10",
    zone: "HAST",
  },
];
export const tableHeaders = {
  users: {
    firstName: "First Name",
    lastName: "Last Name",
    name: "Name",
    email: "Email",
    phone: "Phone",
    building: "Building / Location",
    department: "Department",
    activeUser: "Active User",
    admin: "Admin",
    userType: "User Type",
    action: "Action",
  },
  emergency: {
    location: "Location",
    viewDetails: "Details",
  },
  rallyPoint: {
    buildingName: "Building / Location Name",
    rallyPoint: "Rally Point",
    desc: "Description",
    conatctPerson: "Point of Contact",
    phone: "Phone",
    image: "Image",
  },
  incidentReports: {
    isEmergency: "Is this an emergency?",
    called911: "Was 911 called?",
  },
  common: {
    edit: "Edit",
    remove: "Remove",
    toggle: "Toggle",
    timestamp: "Timestamp",
    user: "User",
    uniqueId: "Unique ID",
    message: "Message",
    notes: "Notes",
    resolved: "Resolved",
    share: "Share",
    response: "Response",
    startDate: "Start Date",
    deactivated: "Deactivated",
    name: "Name",
    status: "Status",
    safetyTeamNotes: "Safety Team Notes",
    extension: "Extension",
  },
};

export const slices = {
  afterHour: "afterHourCheckIn",
  anonymous: "anonymous",
  dashboard: "dashboardMetrics",
  emergencyCheckIn: "emergencyCheckIn",
  importantLink: "importantLink",
  incidents: "incidents",
  manageUser: "manageUser",
  myAccount: "myAccount",
  organization: "organization",
  rallyPoints: "rallyPoints",
  buildings: "buildings",
  safetyOfficers: "safetyOfficers",
};

export const emailBody = {
  incident: "incident",
  anonymous: "anonymous",
  Timestamp: "Timestamp",
  User: "User",
  dept: "User department",
  email: "User email",
  phone: "User phone",
  extension: "Extension",
  incidentDesc: "Incident report description",
  notes: "Notes made by the safety team via the admin portal",
  isResolved: "Has the safety team marked this issue as resolved?",
  anonymousDesc: " Anonymous suggestion description",
};

//Check in interval time json object
export const CHK_IN_INTERVALS = [
  { label: "02", id: 2 },
  { label: "05", id: 5 },
  { label: "10", id: 10 },
  { label: "15", id: 15 },
  { label: "20", id: 20 },
  { label: "25", id: 25 },
  { label: "30", id: 30 },
  { label: "35", id: 35 },
  { label: "40", id: 40 },
  { label: "45", id: 45 },
  { label: "50", id: 50 },
  { label: "55", id: 55 },
  { label: "60", id: 60 },
];
