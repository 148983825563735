import * as XLSX from "xlsx";
import base64 from "base-64";
import utf8 from "utf8";
import moment from "moment";
import {
  DATE_FORMAT_WITH_TIME_PREFIX,
  ACTIVE,
  csvDownload,
  SESSION_ACTIVE,
  tableHeaders,
  DATE_FORMAT_WITHOUT_TIME,
  DATE_FORMAT_TIME,
  ZERO,
  ONE,
  YES_TITLE,
  NO_TITLE,
  DATE_FORMAT_WITH_TIME,
  emailBody,
  THREE,
  HIGH_RISK_STATUS,
  HIGH_RISK_TITLE,
  LOW_RISK_TITLE,
  TimeZoneAbbreviations,
} from "../constants/common";
import { phoneFormat } from "../helpers/validations";
import { getBuildngs, getUserType } from "./manageUser";

//Get localstorage data
export const getLocalStorage = (key?: string) => {
  if (key) {
    return JSON.parse(localStorage.getItem(key) || "{}");
  } else {
    return {};
  }
};

//set  datat to localstorage
export const setLocalStorage = (key?: string, value?: any) => {
  if (key) {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

//Remove local storage
export const removeLocalStorage = (key?: string) => {
  if (key) {
    localStorage.removeItem(key);
  }
};

export const openStore = (url: string) => {
  window.open(url, "_blank");
};

export const removeWhiteSpace = (value?: string) => {
  return value?.replace(/\s/g, "");
};

export const getValueFromObject = (object?: any, property?: string) => {
  if (object) {
    return object[property];
  }
  return null;
};

export const alertMessage = (message?: string) => {
  if (message) {
    alert(message);
  } else {
    alert("Coming soon");
  }
};

//Configure emails
export const sendEmailContactSupport = (
  email?: string,
  message?: string,
  Subject?: string
) => {
  const contactEmail = email || "";
  const subject = Subject || "";
  const emailBody = message || "";
  window.location.href =
    "mailto:" +
    contactEmail +
    "?subject=" +
    subject +
    "&body=" +
    encodeURIComponent(emailBody);
};

//Download CSV by using library
const notAvailableText = "N/A";
export const downloadCSVForChkInDetails = (
  chkIndetails: any,
  list: any,
  fileName: string,
  timeZone?: string
) => {
  const isEmergencyChkInPage = fileName === csvDownload.emergencyChkInDetails;
  const ws: any = [];
  if (list?.length > ZERO) {
    XLSX.utils.sheet_add_json(ws, list, {
      origin: isEmergencyChkInPage ? "A9" : "A12",
      skipHeader: false,
    });
  }

  if (chkIndetails) {
    let detailsArray = [];

    if (isEmergencyChkInPage) {
      let deactivatedUserName = "";
      if (chkIndetails?.deactivated_firstName) {
        deactivatedUserName = chkIndetails?.deactivated_firstName;
      }
      if (chkIndetails?.deactivated_lastName) {
        deactivatedUserName =
          deactivatedUserName + " " + chkIndetails?.deactivated_lastName;
      }
      let emergencyLocations: string = "";
      if (chkIndetails?.buildingId && chkIndetails?.buildingId?.length > ZERO) {
        JSON.parse(chkIndetails?.buildingId)?.map(
          (buildngId: number, index: number) => {
            const selectedBuildingDetails = chkIndetails?.buildings?.filter(
              function (ls: { id: number }) {
                return ls.id === buildngId;
              }
            );

            if (selectedBuildingDetails?.length > ZERO) {
              const buildingName = selectedBuildingDetails?.[ZERO]?.name;
              if (index === ZERO) {
                emergencyLocations = buildingName;
              } else {
                emergencyLocations = emergencyLocations + ",\n " + buildingName;
              }
            }
          }
        );
      }

      detailsArray = [
        {
          A: `Emergency Check-In Activated: ${dateConversion(chkIndetails?.createdOn, DATE_FORMAT_WITH_TIME_PREFIX)} ${timeZone}`,
        },
        {
          A: `Activated by: ${chkIndetails?.firstName} ${chkIndetails?.lastName}`,
        },
        {
          A: `Emergency Check-In Deactivated: ${chkIndetails?.deActivatedTS ? dateConversion(chkIndetails?.deActivatedTS, DATE_FORMAT_WITH_TIME_PREFIX) + " " + timeZone : notAvailableText}`,
        },
        {
          A: `Deactivated by: ${deactivatedUserName ? deactivatedUserName : notAvailableText}`,
        },
        {
          A: `Activated Buildings / Locations: ${emergencyLocations ? emergencyLocations : notAvailableText}`,
        },
        { A: "" },
        { A: `Total need help: ${chkIndetails?.assistanceRecords}` },
        { A: `Total safe: ${chkIndetails?.safeRecords}` },
        { A: `Total no response: ${chkIndetails?.noResponseRecords}` },
      ];
    } else {
      detailsArray = [
        {
          A: `Start time: ${chkIndetails?.session_Start ? dateConversion(chkIndetails?.session_Start, DATE_FORMAT_WITH_TIME_PREFIX) + " " + timeZone : notAvailableText}`,
        },
        {
          A: `Projected end time: ${chkIndetails?.projected_EndTime ? dateConversion(chkIndetails?.projected_EndTime, DATE_FORMAT_WITH_TIME_PREFIX) + " " + timeZone : notAvailableText}`,
        },
        {
          A: `Time deactivated: ${chkIndetails?.session_EndTime ? dateConversion(chkIndetails?.session_EndTime, DATE_FORMAT_WITH_TIME_PREFIX) + " " + timeZone : notAvailableText}`,
        },
        {
          A: `Length of shift: ${chkIndetails?.shiftLength ? chkIndetails?.shiftLength : notAvailableText}`,
        },

        {
          A: `Time remaining: ${chkIndetails?.timeRemaining ? chkIndetails?.timeRemaining : notAvailableText}`,
        },
        {
          A: "",
        },
        {
          A: `Employee Name: ${chkIndetails?.firstName} ${chkIndetails?.lastName}`,
        },
        {
          A: `Risk indicated: ${
            chkIndetails?.risk_Status === HIGH_RISK_STATUS
              ? HIGH_RISK_TITLE
              : LOW_RISK_TITLE
          }`,
        },
        {
          A: `Work location: ${chkIndetails?.floorNo} ${chkIndetails?.building}`,
        },
        {
          A: `Phone: ${chkIndetails?.phone && phoneFormat(chkIndetails?.phone)}`,
        },
        {
          A: `Extension: ${chkIndetails?.extension ? chkIndetails?.extension : notAvailableText}`,
        },
        { A: `Email: ${chkIndetails?.email}` },
      ];
    }
    XLSX.utils.sheet_add_json(ws, detailsArray, {
      origin: "A1",
      skipHeader: true,
    });
  }

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, `${fileName}.csv`);
};

export const downloadCSV = (csvData: any, fileName: string) => {
  const ws = XLSX.utils.json_to_sheet(csvData);

  ws["!cols"] = ws["!cols"] || [];
  ws["!cols"][0] = { width: 30 };

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  // Write to .xlsx file instead of .csv
  XLSX.writeFile(wb, `${fileName}.csv`);
};

//Convert to number from a input
export const convertToNumber = (input: string) => {
  if (input) {
    return Number(input);
  }
  return null;
};

export const sortItems =
  (order?: string, property?: string) => (a: any, b: any) => {
    const itemA = a?.[property];
    const itemB = b?.[property];
    if (order === "asc") {
      return itemA < itemB ? -1 : 1;
    } else {
      return itemA < itemB ? 1 : -1;
    }
  };

export const dateConversion = (value?: string, format?: string) => {
  const convertedDate = dateFormat(value, format);
  return convertedDate;
};

// Format Dates based on time zones
export const dateFormat = (value: string, format: string, isUtc?: boolean) => {
  if (isUtc) {
    return moment(value)
      .utc()
      .format(format || "MM/DD/YY HH:mm:ss");
  }
  return moment(value).format(format || "MM/DD/YY hh:mm:ss A");
};

//Building CSV data and download
export const pushAndFormatCSVArray = (
  data: any,
  page: string,
  timeZone?: string
) => {
  const csvArry: any = [];
  if (
    page === csvDownload.anonymousSuggestion ||
    page === csvDownload.incidentReport
  ) {
    const isAnonymousSugstn = page === csvDownload.anonymousSuggestion;
    const keyName = isAnonymousSugstn
      ? tableHeaders.common.uniqueId
      : tableHeaders.common.user;

    data?.map((data: any) => {
      const commonObjects = {
        [tableHeaders.common.timestamp]: data.createdOn
          ? dateConversion(data.createdOn, DATE_FORMAT_WITH_TIME_PREFIX) +
            " " +
            timeZone
          : "",
        [keyName]: isAnonymousSugstn
          ? data?.uniqueId
          : data?.firstName + data?.lastName,
        [tableHeaders.common.message]: data?.message,
        [tableHeaders.common.notes]: data?.notes,
        [tableHeaders.common.resolved]:
          data.isResolved === ZERO ? NO_TITLE : YES_TITLE,
      };
      if (page === csvDownload.incidentReport) {
        commonObjects[tableHeaders.incidentReports.isEmergency] =
          data?.is_Emergency === ONE ? YES_TITLE : NO_TITLE;
        commonObjects[tableHeaders.incidentReports.called911] =
          data?.have_called_911 === ONE ? YES_TITLE : NO_TITLE;
      }
      csvArry.push(commonObjects);
    });
  } else if (page === csvDownload.users) {
    data?.map((data: any) => {
      csvArry.push({
        [tableHeaders.users.firstName]: data?.firstName,
        [tableHeaders.users.lastName]: data?.lastName,
        [tableHeaders.users.email]: data?.email,
        [tableHeaders.users.phone]: phoneFormat(data?.phone),
        Extension: data?.extension,
        [tableHeaders.users.building]:
          data?.buildingCount > ZERO ? getBuildngs(data?.buildings) : "",
        [tableHeaders.users.department]: data?.department,
        [tableHeaders.users.activeUser]:
          data.isActive === ZERO ? NO_TITLE : YES_TITLE,
        [tableHeaders.users.userType]: getUserType(
          data?.isTempUser,
          data?.isAdmin
        ),
      });
    });
  } else if (page === csvDownload.afterHour) {
    data?.map((data: any) => {
      csvArry.push({
        [tableHeaders.common.startDate]:
          `${dateFormat(data?.session_Start, DATE_FORMAT_WITHOUT_TIME)} at ${dateFormat(data?.session_Start, DATE_FORMAT_TIME)} ${timeZone}`,
        [tableHeaders.common.name]: `${data?.firstName} ${data?.lastName}`,
        [tableHeaders.common.status]:
          data.session_Status === SESSION_ACTIVE
            ? ACTIVE
            : `${[tableHeaders.common.deactivated]}: ${dateFormat(data?.session_EndTime, DATE_FORMAT_WITHOUT_TIME)} at ${dateFormat(data?.session_EndTime, DATE_FORMAT_TIME)} ${timeZone}`,
      });
    });
  } else if (page === csvDownload.emergencyChkIn) {
    data?.map((data: any) => {
      csvArry.push({
        [tableHeaders.common.startDate]:
          `${dateFormat(data?.createdOn, DATE_FORMAT_WITHOUT_TIME)} at ${dateFormat(data?.createdOn, DATE_FORMAT_TIME)} ${timeZone}`,
        [tableHeaders.common.name]: `${data?.firstName} ${data?.lastName}`,
        [tableHeaders.common.status]:
          data.isActive === SESSION_ACTIVE
            ? ACTIVE
            : `${[tableHeaders.common.deactivated]}: ${dateFormat(data?.deActivatedTS, DATE_FORMAT_WITHOUT_TIME)} 
            at ${dateFormat(data?.deActivatedTS, DATE_FORMAT_TIME)} ${timeZone}`,
      });
    });
  }
  return csvArry;
};

export const toggleCheck = (id: string) => {
  let eHeight: any = "";
  let nHeight: any = "";
  const tdexp = document.querySelector(`td[data-id="${id}"]`);
  if (tdexp) {
    tdexp.classList.add("messages");
    tdexp.classList.add("expand");
    const spanExp = tdexp.querySelector(".d-block");
    if (spanExp) {
      const clientHeightExp = spanExp.clientHeight;
      eHeight = clientHeightExp;
    }
  }
  const td = document.querySelector(`td[data-id="${id}"]`);
  if (td) {
    td.classList.remove("expand");
    const span = td.querySelector(".d-block") as HTMLElement;
    if (span) {
      const clientHeight = span.clientHeight;
      nHeight = clientHeight;
    }
  }

  return [eHeight, nHeight];
};

//Building api response data
export const buildResponse = async (res: any) => {
  const response: any = {
    status: res?.status,
    message: res?.message,
  };
  if (res?.data) {
    response.data = res?.data;
  }
  if (res?.url) {
    response.urlError = res?.url;
  }
  return response;
};

//Clear user session and localstorage
export const clearUserSession = () => {
  localStorage.clear();
  window.location.href = "/";
};

//Screen move to top
export const moveToTop = () => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: "auto",
  });
};

//Getting current year from a date
export const getCurrentYear = () => {
  const date = new Date();
  return date.getFullYear().toString();
};

//Checking the device is mobile or not
export const isMobileDevice = () => {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    return true;
  }
  return false;
};

//Build customised email body for share functionality
export const buildEmailBody = (data: any, page: string) => {
  if (page === emailBody.incident) {
    return `${emailBody.Timestamp}: ${
      data?.createdOn && dateConversion(data.createdOn, DATE_FORMAT_WITH_TIME)
    }\n
      ${emailBody.User}: ${data?.firstName} ${data?.lastName}\n
      ${emailBody.dept}: ${data?.department}\n
      ${emailBody.email}: ${data?.email}\n
      ${emailBody.phone}:  ${data?.phone && formatPhoneNumber(data?.phone)}\n
      ${emailBody.extension}:  ${data?.extension ? data?.extension : notAvailableText}\n
      ${emailBody.incidentDesc}:  ${data?.message}\n
      ${emailBody.notes}: ${data?.notes ? data?.notes : ""}\n
      ${emailBody.isResolved}: ${data?.isResolved === ONE ? YES_TITLE : NO_TITLE}`;
  } else {
    return `${emailBody.Timestamp}: ${
      data?.createdOn && dateConversion(data.createdOn, DATE_FORMAT_WITH_TIME)
    }\n
      ${emailBody.anonymousDesc}: ${data?.message}\n
      ${emailBody.notes}: ${data?.notes ? data?.notes : ""}\n
      ${emailBody.isResolved}:  ${data?.isResolved === ONE ? YES_TITLE : NO_TITLE}`;
  }
};

//US formating of phone number
export const formatPhoneNumber = (phoneNumber: any) => {
  if (phoneNumber !== null) {
    phoneNumber = phoneNumber?.replace(/^(\+1)/, "");
    phoneNumber = phoneNumber?.replace(/\D/g, "");

    const firstNo = phoneNumber?.slice(ZERO, THREE);
    const remainNo = phoneNumber?.slice(THREE);

    const formattedNumber = `(${firstNo}) ${remainNo?.slice(
      ZERO,
      THREE
    )}-${remainNo?.slice(THREE)}`;

    return formattedNumber;
  }
};

export const setCookie = (
  cookieName: string,
  object: { email: string; expiry: Date },
  days: number
) => {
  const expires = new Date();
  //expires.setTime(expires.getTime() + days * 60 * 1000);
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000); // Calculate the expiration time
  document.cookie = `${cookieName}=${JSON.stringify(object)};expires=${expires.toUTCString()};path=/`;
};

export const getCookie = (cookieName: string) => {
  const value = `; ${document.cookie}`;
  const parts = value?.split(`; ${cookieName}=`);

  const cookieValues: any = JSON.parse(
    parts?.pop()?.split(";")?.shift() || null
  );
  const expiryDate = new Date(cookieValues?.expiry);
  const currentDate = new Date();
  if (expiryDate) {
    if (expiryDate > currentDate) {
      return { ...cookieValues, isExpired: false };
    } else {
      return { ...cookieValues, isExpired: true };
    }
  } else {
    return { isExpired: true };
  }
  return null;
};

export const removeCookie = (cookieName: string) => {
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
};

// Get Time zone abbrevation
export const getTimeZone = () => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const dateTimeFormat = new Intl.DateTimeFormat("en-US", {
    timeZone,
    timeZoneName: "short",
  });
  // Extract and return the time zone abbreviation
  const parts = dateTimeFormat.formatToParts(new Date());
  const timeZonePart = parts.find((part) => part.type === "timeZoneName");
  if (timeZonePart?.value) {
    const timeZoneAbbrvtion = TimeZoneAbbreviations?.filter(function (ls: {
      GMT: string;
    }) {
      return ls?.GMT === timeZonePart.value;
    });
    if (timeZoneAbbrvtion && timeZoneAbbrvtion?.length > ZERO) {
      return timeZoneAbbrvtion?.[ZERO]?.zone;
    }
    return timeZonePart.value;
  }
  return null;
};

//For getting the month from the date
export const getMonthFormDate = (date: Date | null) => {
  const selectedDate = date && new Date(date);
  if (selectedDate) {
    if (selectedDate?.getMonth() === ZERO) {
      return ONE;
    } else {
      return selectedDate?.getMonth() + ONE;
    }
  }
};

export const generateTimeArray = () => {
  const times = [];
  const TWO = 2;

  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      const period = hour < 12 ? "AM" : "PM";
      const formattedHour = String(hour % 12 || 12).padStart(TWO, "0"); // Convert 0 to 12 for 12-hour format
      const formattedMinute = String(minute).padStart(TWO, "0");
      const finalFormattedTime = `${formattedHour}:${formattedMinute} ${period}`;
      const newItem = {
        label: finalFormattedTime,
        id: finalFormattedTime,
      };
      times.push(newItem);
    }
  }
  return times;
};

// Enrypt text
export const encryptText = (text: string) => {
  if (text) {
    const bytes = utf8.encode(text.toString());
    const encodedText = base64.encode(bytes);
    return encodedText;
  }
  return null;
};

// Decrypt text
export const decryptText = (text: any) => {
  if (text) {
    const bytes = utf8.decode(text);
    const decodedText = base64.decode(bytes);
    return decodedText;
  }
  return null;
};
