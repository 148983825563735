/**
 * @file   src\components\SLMultiSelectWithGrouping.tsx
 * @brief  Reusable Multi Select component.
 * @date   Oct, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import "./SLMultiSelect.scss";
import Multiselect from "multiselect-react-dropdown";
import {
  MultifilterObjects,
  MultiSelect,
} from "../interfaces/GeneralInterface";
import { ONE, ZERO } from "../constants/common";
import { typeBuilding, userType } from "../constants/manageUsers";

const SLMultiSelectWithGrouping = (props: MultiSelect) => {
  const userTypeOptionsValues = {
    isAdmin: -1,
    isGeneralUser: -2,
    isTemporary: -3,
  };

  const userTypeOptions = [
    {
      name: "Admin",
      id: userTypeOptionsValues.isAdmin,
      type: userType,
    },
    {
      name: "General",
      id: userTypeOptionsValues.isGeneralUser,
      type: userType,
    },
    {
      name: "Temporary",
      id: userTypeOptionsValues.isTemporary,
      type: userType,
    },
  ];
  const buildingOptions = props?.options?.map((item: any) => {
    return { id: item?.id, name: item?.name, type: typeBuilding };
  });
  let optionsWithGroups = [...userTypeOptions];
  if (buildingOptions?.length > ZERO) {
    optionsWithGroups = [...userTypeOptions, ...buildingOptions];
  }

  //Updating the  state by adding the selected option
  const onSelect = (selectedList: MultifilterObjects[]) => {
    props?.handleSelection(selectedList);
  };

  //Updating the  state by removing the  option
  const onRemove = (selectedList: MultifilterObjects[]) => {
    props?.handleSelection(selectedList);
  };

  const selectedOptions = props?.value;
  const optionsCount =
    selectedOptions?.length > props?.options?.length
      ? selectedOptions?.length - ONE
      : selectedOptions?.length;
  const isOptionsSelected = optionsCount > ZERO;

  const getPlaceHolderText = () => {
    if (!isOptionsSelected) {
      return "Select";
    } else {
      const userTypeOptions = selectedOptions?.filter(function (ls: {
        id: number;
      }) {
        return ls.id < ZERO;
      });
      const buildingOptions = selectedOptions?.filter(function (ls: {
        id: number;
      }) {
        return ls.id > ZERO;
      });
      if (userTypeOptions?.length > ZERO && buildingOptions?.length > ZERO) {
        return `${userTypeOptions?.length} User Type & ${buildingOptions?.length} Building / Location Selected`;
      } else if (
        userTypeOptions?.length > ZERO &&
        buildingOptions?.length === ZERO
      ) {
        return `${userTypeOptions?.length} User Type Selected`;
      } else if (
        userTypeOptions?.length === ZERO &&
        buildingOptions?.length > ZERO
      ) {
        return `${buildingOptions?.length} Building / Location Selected`;
      }
    }
  };

  return (
    <div className="sl-multiselect">
      {props.label && <label>{props.label}</label>}
      <Multiselect
        displayValue="name"
        selectedValues={selectedOptions}
        onRemove={onRemove}
        onSelect={onSelect}
        placeholder={getPlaceHolderText()}
        showArrow={true}
        hideSelectedList={true}
        avoidHighlightFirstOption={true}
        showCheckbox
        className={isOptionsSelected ? "selected-buildings" : ""}
        options={optionsWithGroups}
        groupBy="type"
      />
    </div>
  );
};

export default SLMultiSelectWithGrouping;
