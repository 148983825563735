import { createAsyncThunk } from "@reduxjs/toolkit";
import { getOrganization, getTimeZoneList } from "../../services/organization";
import { GetOrgRequest } from "../../interfaces/Organization";
import { getStates } from "../../services/commonServices";

// Action creator to call the organisation details function with a payload to invoke the API.
export const fetchOrganizationDetails = createAsyncThunk(
  "organization/fetchOrganizationDetails",
  (payload: GetOrgRequest) => {
    return getOrganization(payload);
  }
);

// Action creator to call the state list function  to invoke the statelist API.
export const fetchStateLists = createAsyncThunk("common/stateLists", () => {
  return getStates();
});

// Action creator to call the time zone list  function to invoke the API.
export const fetchTimeZoneList = createAsyncThunk(
  "organization/fetchTimeZoneList",
  () => {
    return getTimeZoneList();
  }
);
