export const textBoxErrMessagePrefix = "Enter ";
export const selectBoxErrMessagePrefix = "Select ";
export const ENTER_FIRST_NAME = `${textBoxErrMessagePrefix}First Name.`;
export const ENTER_LAST_NAME = `${textBoxErrMessagePrefix}Last Name.`;
export const ENTER_PHONE = `${textBoxErrMessagePrefix}Phone.`;
export const ENTER_EMAIL = `${textBoxErrMessagePrefix}Email.`;
export const ENTER_BUILDING = `${textBoxErrMessagePrefix}Building.`;
export const SELECT_BUILDING = `${selectBoxErrMessagePrefix}Building / Location.`;
export const ENTER_DEPARTMENT = `${textBoxErrMessagePrefix}Department.`;
export const ENTER_VALID_PHONE = `${textBoxErrMessagePrefix}a valid phone.`;
export const INVALID_EMAIL = "Invalid Email";
export const ENTER_2FA_OTP = "Enter Verification Code";
export const INVALID_SIGNATURE = "invalid signature";
export const PASSWORD_MISMATCH =
  "New Password and Confirm New Password does not match.";
export const RE_ENTER_PASSWORD = "Re enter Password";
export const ENTER_CURRENT_PASSWORD = `${textBoxErrMessagePrefix}current password.`;
export const ENTER_NEW_PASSWORD = `${textBoxErrMessagePrefix}new password.`;
export const ENTER_CONFIRM_PASSWORD = `${textBoxErrMessagePrefix}confirm new password.`;
export const SELECT_SAFETY_OFFICER = `${selectBoxErrMessagePrefix}a Safety Officer.`;
export const ENTER_TITLE = `${textBoxErrMessagePrefix}Title.`;
export const ENTER_VALID_URL = `${textBoxErrMessagePrefix}a valid URL.`;
export const ENTER_LINK = `${textBoxErrMessagePrefix}Link text to display.`;
export const SELECT_SUPPORT_REASON = `${selectBoxErrMessagePrefix} support reason.`;
export const ENTER_URL = `${textBoxErrMessagePrefix}URL.`;
export const ENTER_DESCRIPTION = `${textBoxErrMessagePrefix}Description.`;
export const ENTER_PASSWORD = `${textBoxErrMessagePrefix}Password.`;
export const CONFIRM_PASSWORD_VALIDATION = `Confirm Password.`;
export const INVALID_IMAGE_TYPE = "Image should be of png/jpeg formats.";
export const INVALID_IMAGE_SIZE =
  "Image size should be less than or equal to 5MB.";
export const INVALID_FILE_SIZE =
  "File size should be less than or equal to 5MB.";
export const INVALID_IMAGE_DIMENSION =
  "Image should be of minimum dimensions [RESOLUTION] pixels.";
export const ENTER_BUILDING_NAME = `${textBoxErrMessagePrefix}Building / Location Name.`;
export const ENTER_RALLY_POINT = `${textBoxErrMessagePrefix}Rally Point.`;
export const ENTER_POINT_CONTRACT = `${textBoxErrMessagePrefix}Point of Contact.`;
export const SELECT_IMAGE = `${selectBoxErrMessagePrefix}an Image.`;
export const SELECT_LOGO = `${selectBoxErrMessagePrefix}Logo.`;
export const SELECT_FILE = `${selectBoxErrMessagePrefix}CSV File.`;
export const SELECT_START_DATE = `${selectBoxErrMessagePrefix}Start Date.`;
export const SELECT_END_DATE = `${selectBoxErrMessagePrefix}End Date.`;
export const SELECT_START_TIME = `${selectBoxErrMessagePrefix}Start Time.`;
export const INVALID_START_TIME = `Start time should be less than end time.`;
export const SELECT_END_TIME = `${selectBoxErrMessagePrefix}End Time.`;
export const ENTER_ORG_NAME = `${textBoxErrMessagePrefix}Organization Name.`;
export const SELECT_TIMEZONE = `Select Time Zone.`;
export const ENTER_MAIN_ADDRESS = `${textBoxErrMessagePrefix}Address.`;
export const ENTER_ADDRESS2 = `${textBoxErrMessagePrefix}Address Line 2.`;
export const ENTER_CITY = `${textBoxErrMessagePrefix}City.`;
export const SELECT_STATE = `${selectBoxErrMessagePrefix}State.`;
export const ENTER_ZIP = `${textBoxErrMessagePrefix}ZIP code.`;
export const SELECT_HIGH_RISK_INTERVALS = `${selectBoxErrMessagePrefix}Minutes.`;
export const SELECT_LOW_RISK_INTERVALS = `${selectBoxErrMessagePrefix}Minutes.`;
export const ENTER_NOTES = `${textBoxErrMessagePrefix}notes.`;
export const INVALID_NOTES = `Invalid notes.`;
export const INVALID_CONTENT = `Invalid contents.`;
export const RATE_LIMIT_EXCEED =
  "You have exceed the rate limit. Please try again after some times.";
