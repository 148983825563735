/**
 * @file   src\containers\IncidentReports.tsx
 * @brief  IncidentReports page.
 * @date   Feb, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import Strings from "../assets/strings/Strings.json";
import { Button, Col, Row, Table, Form } from "react-bootstrap";
import ICTableExpand from "../assets/img/icons/TableExpand";
import ICShare from "../assets/img/icons/Share";
import { useState, useEffect } from "react";
import { updateIncidentReport } from "../services/incidentService";
import {
  DATE_FORMAT_WITH_TIME,
  HTTP_RESPONSE_STATUS_200,
  ONE,
  PAGE_SIZE_20,
  NOTE,
  ZERO,
  csvDownload,
  tableHeaders,
  noEmail,
  emailBody,
  WIDTH_220,
} from "../constants/common";
import {
  sendEmailContactSupport,
  downloadCSV,
  pushAndFormatCSVArray,
  toggleCheck,
  dateConversion,
  moveToTop,
  buildEmailBody,
  getTimeZone,
} from "../helpers/common";
import AddEditNote from "../components/common/AddEditNote";
import TableHeader from "../components/common/TableHeader";
import Paginate from "../components/common/Paginate";
import Search from "../components/common/Search";
import { fetchIncidentReport } from "../Redux/actions/incidentReport";
import { useAppSelector, useAppDispatch } from "../Redux/hooks";
import { ResponseObjects } from "../interfaces/AxiosResponse";
import { SearchRequest } from "../interfaces/Search";

const IncidentReports = () => {
  const [incidentReportList, setIncidentReportList] = useState<any>([]);
  const [csvData, setCsvData] = useState<any>([]);
  const [page, setPage] = useState<number>(ONE);
  const [perPage, setPerPage] = useState<number>(PAGE_SIZE_20);
  const [totalRecord, setTotalRecord] = useState<number>(ZERO);
  const [searchText, setSearchText] = useState<string>("");
  const mailSubject = "SpotLight Saftey Incident Report";
  const timeZone = getTimeZone();
  const incidentReports = useAppSelector(
    (state: any) => state?.incidents?.list
  );
  const incidentReportsLoading = useAppSelector(
    (state: any) => state.incidents?.loading
  );
  const dispatch = useAppDispatch();

  const columns = [
    { title: tableHeaders.common.toggle, isSort: false },
    { title: tableHeaders.common.timestamp, isSort: false },
    { title: tableHeaders.common.user, isSort: false },
    { title: tableHeaders.common.message, isSort: false },
    { title: tableHeaders.common.notes, isSort: false },
    {
      title: tableHeaders.common.resolved,
      isSort: false,
      thClass: "text-center",
    },
    {
      title: tableHeaders.common.share,
      isSort: false,
      thClass: "text-center",
    },
  ];

  // Handle to calling the incident-report list API.
  useEffect(() => {
    dispatch(
      fetchIncidentReport({
        pageNo: page,
        pageSize: perPage,
        searchText: searchText,
      })
    );
  }, [page, perPage]);

  // Handle calling the incident-report list API with a search payload.
  const onSearchClick = (payload: SearchRequest) => {
    dispatch(fetchIncidentReport(payload));
  };

  // Handle response from the incident-report list api call.
  useEffect(() => {
    if (!incidentReportsLoading) {
      loadIncidentReports();
    }
  }, [incidentReports]);

  // Handle setting the response from the incident-report list API call into state.
  const loadIncidentReports = async () => {
    const list = incidentReports?.list;
    if (list) {
      const csvArry: [] = pushAndFormatCSVArray(
        list,
        csvDownload.incidentReport,
        timeZone
      );
      setTotalRecord(incidentReports?.totalRecords);
      setCsvData(csvArry);
      setIncidentReportList(list);
      getToggleDisabled(list);
    } else setTotalRecord(ZERO);
  };

  // Handle updating the note and isResolved state and calling the update incident report API.
  const updateIncident = async (
    id: number,
    value: number | string,
    type: string
  ) => {
    let payload: any = {};
    if (type === NOTE) {
      payload = {
        id: id,
        notes: value,
      };
    } else {
      payload = {
        id: id,
        resolved: value,
      };
    }
    const response: ResponseObjects = await updateIncidentReport(payload);
    if (response?.status === HTTP_RESPONSE_STATUS_200) {
      const copyList = [...incidentReportList];
      const newState = copyList.map((obj) => {
        if (obj.iR_Id === id) {
          if (type === NOTE) {
            return { ...obj, notes: value, toggleDisabled: false };
          } else {
            return { ...obj, isResolved: value };
          }
        }
        return obj;
      });

      const csvArry: any = pushAndFormatCSVArray(
        newState,
        csvDownload.incidentReport,
        timeZone
      );
      setIncidentReportList(newState);
      setCsvData(csvArry);
    }
    return response?.status;
  };

  // Handle calling the update incident-report API for resolved switch payload.
  const handleResolvedSwitch = (id: number, value: number) => {
    updateIncident(id, value === ZERO ? ONE : ZERO, "");
  };

  // Handle toggle change to expand note and message.
  const handleToggleChange = (id: number, message: string, notes: string) => {
    if (message || notes) {
      const updatedArray = incidentReportList.map((obj: any) => {
        if (obj.iR_Id === id) {
          return { ...obj, toggle: !obj.toggle };
        }
        return obj;
      });
      setIncidentReportList(updatedArray);
    }
  };

  // page change and state change
  const pageChange = (page: number) => {
    if (page) {
      setPage(page);
      moveToTop();
    }
  };

  // page size change and state change
  const perPageChange = (page: number) => {
    if (page) {
      setPage(ONE);
      setPerPage(page);
      moveToTop();
    }
  };

  // Handle to disable the toggle for empty note and message incident-report list.
  const getToggleDisabled = (incidentData: []) => {
    if (incidentData) {
      setTimeout(() => {
        const updatedArray: any = [];
        incidentData.map((obj: any) => {
          const [eHeight, nHeight] = toggleCheck(obj.iR_Id);
          if (!obj.notes && eHeight === nHeight) {
            updatedArray.push({ ...obj, toggleDisabled: true });
          } else {
            updatedArray.push({ ...obj, toggleDisabled: false });
          }
        });
        setIncidentReportList(updatedArray);
      }, 100);
    }
  };

  // Bind incidents list in the UI
  const bindIncidentsList = () => {
    if (incidentReportsLoading) {
      return (
        <Col className="text-center no-record mt-5">
          <output className="spinner-border"></output>
        </Col>
      );
    } else {
      if (incidentReportList?.length > ZERO) {
        return (
          <Table responsive>
            <TableHeader columns={columns} />
            <tbody>
              {incidentReportList.map((items: any) => (
                <tr key={items?.iR_Id}>
                  <td
                    className="toggle"
                    onClick={() => {
                      handleToggleChange(
                        items.iR_Id,
                        items?.message,
                        items?.notes
                      );
                    }}
                  >
                    <span
                      className={
                        items?.toggleDisabled
                          ? "disabled"
                          : !items.toggle
                            ? "tableExpand"
                            : "tableCollapse"
                      }
                    >
                      <ICTableExpand />
                    </span>
                  </td>
                  <td width={WIDTH_220}>
                    {items.createdOn &&
                      dateConversion(
                        items.createdOn,
                        DATE_FORMAT_WITH_TIME
                      )}{" "}
                    {timeZone}
                  </td>
                  <td className="user">
                    <span>
                      {items?.firstName} {items?.lastName}
                    </span>
                  </td>
                  <td
                    data-id={items?.iR_Id}
                    className={!items.toggle ? "messages" : "messages expand"}
                  >
                    <span className="d-block">{items?.message}</span>
                  </td>

                  <AddEditNote
                    handleSave={updateIncident}
                    items={items}
                    id={items?.iR_Id}
                  />

                  <td className="tb-switch-center">
                    <Form.Check
                      type="switch"
                      value={items?.isResolved}
                      checked={items?.isResolved}
                      onChange={() =>
                        handleResolvedSwitch(items?.iR_Id, items?.isResolved)
                      }
                      id="custom-switch"
                    />
                  </td>
                  <td className="ic-yellow">
                    <a
                      role="button"
                      href={void 0}
                      onClick={() =>
                        sendEmailContactSupport(
                          noEmail,
                          buildEmailBody(items, emailBody.incident),
                          mailSubject
                        )
                      }
                    >
                      <ICShare />
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        );
      } else {
        return (
          <Col className="text-center mt-3 mb-3">
            {Strings.IncidentReport.NoDataMessage}
          </Col>
        );
      }
    }
  };

  return (
    <>
      <Row className="tilte-wrapper">
        <Col lg="12">
          <h1>
            <span className="text-uppercase">
              {Strings.Common.InnerSubTitle}
            </span>
            <br />
            {Strings.IncidentReport.Title}
          </h1>
        </Col>
      </Row>
      <Row className="action-wrapper">
        <Col md="6" xl="4">
          <Search
            onClick={onSearchClick}
            pageSize={perPage}
            setPage={setPage}
            page={page}
            searchText={searchText}
            setSearchText={setSearchText}
          />
        </Col>
        <Col md="6" xl="8" className="text-end">
          <Button
            disabled={incidentReportList?.length === ZERO}
            onClick={() => downloadCSV(csvData, csvDownload.incidentReport)}
          >
            {Strings.common.dowloadCSV}
          </Button>
        </Col>
      </Row>

      {bindIncidentsList()}
      {totalRecord !== ZERO && (
        <Paginate
          totalRecords={totalRecord}
          currentPage={page}
          perPage={perPage}
          onClick={pageChange}
          onShowClick={perPageChange}
        />
      )}
    </>
  );
};

export default IncidentReports;
