/**
 * @file   src\config\axiosConfig.ts
 * @brief  This file is responsible for axios client configurations.
 * @date   March, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import axios, { AxiosRequestConfig } from "axios";
import {
  clearUserSession,
  getLocalStorage,
  setLocalStorage,
} from "../helpers/common";
import {
  API_TIMEOUT,
  CONTENT_TYPE_FORM_DATA,
  HTTP_RESPONSE_STATUS_403,
  HTTP_RESPONSE_STATUS_500,
  HTTP_STATUS_429,
  HTTP_STATUS_498,
  USER_INFO_KEY,
} from "../constants/common";
import { REFRESH_TOKEN_API } from "../constants/endPoints";
import { HTTP_POST } from "../constants/common";
import {
  INVALID_SIGNATURE,
  RATE_LIMIT_EXCEED,
} from "../constants/validationMessages";
import { ResponseObjects } from "../interfaces/AxiosResponse";
import { toast } from "react-toastify";

// Craete axios instance.
const axiosClient = axios.create();

// Set axios headers.
const headers: any = {};

//Renew access token using refresh token.
const renewAccessToken = async () => {
  try {
    const storageData = getLocalStorage(USER_INFO_KEY);
    const refToken = storageData ? storageData.refreshToken : "";

    const res: ResponseObjects = await axiosClient({
      url: REFRESH_TOKEN_API,
      method: HTTP_POST,
      data: { refreshToken: refToken },
    });

    if (
      res?.status === HTTP_RESPONSE_STATUS_500 ||
      res?.status === HTTP_RESPONSE_STATUS_403
    ) {
      alert(res?.data?.message);
      clearUserSession();
    } else {
      return res?.data;
    }
  } catch (error: any) {
    clearUserSession();
    //return Promise.reject(error.message);
  }
};

// Assign default values.
axiosClient.defaults.baseURL = process.env.REACT_APP_API_URL;
axiosClient.defaults.headers = headers;
axiosClient.defaults.timeout = API_TIMEOUT;

// Define the structure of a retry queue item.
type RetryQueueItem = {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
  config: AxiosRequestConfig;
};

// Create a list to hold the request queue.
const refreshAndRetryQueue: RetryQueueItem[] = [];

// Flag to prevent multiple token refresh requests.
let isRefreshing = false;
let isExceedRateLimit = false;

// Handle interceptor request.
axiosClient.interceptors.request.use(
  async (config: any) => {
    const storageData = getLocalStorage(USER_INFO_KEY);
    const token = storageData ? storageData.accessToken : "";
    const isBulkUpload = config?.url?.includes("bulkUpload");

    config.headers["Access-Control-Allow-Origin"] = "*";
    config.headers["Access-Control-Allow-Headers"] = "*";
    config.headers["Access-Control-Allow-Credentials"] = true;
    config.headers["Access-Control-Allow-Methods"] =
      "GET, POST, PUT, PATCH, DELETE";
    config.headers["Cache-Control"] = "no-cache";
    config.headers["X-XSS-Protection"] = "1; mode=block";
    config.headers["Content-Security-Policy"] =
      "default-src 'self'; img-src 'self'";
    config.headers["api_key"] = process.env.REACT_APP_API_KEY;
    if (!config?.headers?.isFromSuperAdmin) {
      config.headers["Authorization"] = `Bearer ` + token;
    }
    //Setup recaptcha token in headers for google recaptcha verification while login
    if (config?.data?.recaptchaToken) {
      config.headers["recaptcha_token"] = config.data.recaptchaToken;
      delete config?.data?.["recaptchaToken"];
    }
    //Setup type for checking the device type ie, whether the call from app or portal. This is used for trouble login email sending api.
    if (config?.data?.type) {
      config.headers["type"] = config.data.type;
      delete config?.data?.["type"];
    }

    // Modify the request.
    // Exclude authorization in file upload to s3
    if (config?.headers?.["Content-Type"] === CONTENT_TYPE_FORM_DATA) {
      config.headers["Content-Type"] = CONTENT_TYPE_FORM_DATA;
      if (!isBulkUpload) {
        // Need to remove authorization and api key while upload image to S3.
        config.headers["Authorization"] = null;
        config.headers["api_key"] = null;
      }
    } else {
      config.headers["Content-Type"] = "application/json";
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Handle interceptor response.
axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    console.log(error);
    const originalRequest: AxiosRequestConfig = error.config;
    if (error.response && error.response.status === HTTP_STATUS_498) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          // Refresh the access token
          const res = await renewAccessToken();
          const newAccessToken = res?.data?.accessToken || "";
          const newRefreshtoken = res?.data?.refreshToken || "";

          //Update the request headers with the new access token
          error.config.headers["Authorization"] = `Bearer ` + newAccessToken;
          const storageData = getLocalStorage(USER_INFO_KEY);
          storageData.accessToken = newAccessToken;
          storageData.refreshToken = newRefreshtoken;
          setLocalStorage(USER_INFO_KEY, storageData);
          // Retry all requests in the queue with the new token
          refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
            axiosClient
              .request(config)
              .then((response) => resolve(response))
              .catch((err) => reject(err));
          });

          // Clear the queue
          refreshAndRetryQueue.length = 0;

          // Retry the original request
          return axiosClient(originalRequest);
          // eslint-disable-next-line no-useless-catch
        } catch (refreshError) {
          clearUserSession();
        } finally {
          isRefreshing = false;
        }
      }
      // Add the original request to the queue
      return new Promise<void>((resolve, reject) => {
        refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
      });
    } else if (error.response.status === HTTP_STATUS_429) {
      // Handle rate limit issue
      if (!isExceedRateLimit) {
        isExceedRateLimit = true;
        try {
          const isLogout = error?.config?.url?.includes("logout");
          !isLogout &&
            toast.error(RATE_LIMIT_EXCEED, {
              toastId: new Date().toString(),
            });
        } catch {
          // Error
        } finally {
          isExceedRateLimit = false;
        }
      }
    } else {
      //Checking invalid signature error and clear session and logout.
      const responseData = error.response?.data;
      if (responseData?.data === INVALID_SIGNATURE) {
        clearUserSession();
      }
    }
    // Return a error respons if the status code is not 401
    return { status: error?.response?.status, data: error?.response?.data };
  }
);
// Export default methods.
export default axiosClient;
