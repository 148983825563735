/**
 * @file   src\containers\Dashboard.tsx
 * @brief  Dashboard page.
 * @date   Feb, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import Strings from "../assets/strings/Strings.json";
import { Col, Row } from "react-bootstrap";
import ICIncident from "../assets/img/ICIncidentReport.svg";
import ICAfterHours from "../assets/img/ICAfterHours.svg";
import ICManagerUser from "../assets/img/ICManageUser.svg";
import ICSafety from "../assets/img/ICSafety.svg";
import ICLinks from "../assets/img/ICLinks.svg";
import ICAnonymous from "../assets/img/ICAnonymous.svg";
import { useNavigate } from "react-router-dom";
import { pageURLs } from "../constants/pageURLs";
import React, { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../Redux/hooks";
import { fetchDashboardMetrics } from "../Redux/actions/dashboard";
import { getLocalStorage } from "../helpers/common";
import { USER_INFO_KEY } from "../constants/common";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userInfoFromLS = getLocalStorage(USER_INFO_KEY);

  // Metrics data from redux store
  const dashboardMetrics = useAppSelector(
    (state: any) => state.dashboard?.data
  );

  // Metrics loading status from redux store
  const metricsIsLoading = useAppSelector(
    (state: any) => state.dashboard?.loading
  );

  //Dispatch action for fetching dashboard metrics
  useEffect(() => {
    dispatch(fetchDashboardMetrics());
  }, []);
  return (
    <>
      <Row className="tilte-wrapper">
        <Col xl="12">
          <h1>
            <span className="text-uppercase">
              {Strings.Common.InnerSubTitle}
            </span>
            <br />
            {Strings.Dashboard.Title}
          </h1>
        </Col>
      </Row>
      <div className="content-wrapper dashboard welcome">
        <p>
          {Strings.Dashboard.Welcome} <span>Admin,</span>
        </p>
        {Strings.Dashboard.LiketoDo}
      </div>
      {!metricsIsLoading && (
        <>
          <Row>
            <Col xl="6">
              <div
                className="content-wrapper dashboard summary-items emergency d-flex flex-column justify-content-center"
                onClick={() => navigate(pageURLs.emergencyCheckIn)}
              >
                <h5>{Strings.Dashboard.EmergencyTitle}</h5>
                <p className="mb-0">{Strings.Dashboard.EmergencyTxt}</p>
              </div>
            </Col>
            <Col md="6" xl="3">
              <div
                className="content-wrapper dashboard summary-items d-flex justify-content-between"
                onClick={() => navigate(pageURLs.incidentReports)}
              >
                <div className="left-wrap">
                  <span className="icon-wrap d-block">
                    <img src={ICIncident} alt="" />
                  </span>
                  <h5 className="mb-0"><span className="d-block">OPEN</span>Incident Reports</h5>
                </div>
                <div className="right-wrap blue d-flex align-items-center">
                  {dashboardMetrics?.incidentCount}
                </div>
              </div>
            </Col>
            <Col md="6" xl="3">
              <div
                className="content-wrapper dashboard summary-items d-flex justify-content-between"
                onClick={() => navigate(pageURLs.workAloneModeIn)}
              >
                <div className="left-wrap">
                  <span className="icon-wrap d-block">
                    <img src={ICAfterHours} alt="" />
                  </span>
                  <h5 className="mb-0"><span className="d-block">ACTIVE</span>Work Alone</h5>
                </div>
                <div className="right-wrap blue d-flex align-items-center">
                  {dashboardMetrics?.afterHourCount}
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="6" xl="3">
              <div
                className="content-wrapper dashboard summary-items d-flex justify-content-between"
                onClick={() => navigate(pageURLs.manageusers)}
              >
                <div className="left-wrap">
                  <span className="icon-wrap d-block">
                    <img src={ICManagerUser} alt="" />
                  </span>
                  <h5 className="mb-0">
                    Manage
                    <br />
                    Users
                  </h5>
                </div>
                <div className="right-wrap d-flex align-items-center">
                  {dashboardMetrics?.userCount}
                </div>
              </div>
            </Col>
            <Col md="6" xl="3">
              <div
                className="content-wrapper dashboard summary-items d-flex justify-content-between"
                onClick={() => navigate(pageURLs.safetyOfficers)}
              >
                <div className="left-wrap">
                  <span className="icon-wrap d-block">
                    <img src={ICSafety} alt="" />
                  </span>
                  <h5 className="mb-0">
                    Manage
                    <br />
                    Safety Contacts
                  </h5>
                </div>
                <div className="right-wrap d-flex align-items-center">
                  {dashboardMetrics?.soCount}
                </div>
              </div>
            </Col>
            <Col md="6" xl="3">
              <div
                className="content-wrapper dashboard summary-items d-flex justify-content-between"
                onClick={() => navigate(pageURLs.importantlinks)}
              >
                <div className="left-wrap">
                  <span className="icon-wrap d-block">
                    <img src={ICLinks} alt="" />
                  </span>
                  <h5 className="mb-0">
                    Manage
                    <br />
                    Important Links
                  </h5>
                </div>
                <div className="right-wrap d-flex align-items-center">
                  {dashboardMetrics?.linkCount}
                </div>
              </div>
            </Col>
            <Col md="6" xl="3">
              <div
                className="content-wrapper dashboard summary-items d-flex justify-content-between"
                onClick={() => navigate(pageURLs.anonymoussuggestionbox)}
              >
                <div className="left-wrap">
                  <span className="icon-wrap d-block">
                    <img src={ICAnonymous} alt="" />
                  </span>
                  <h5 className="mb-0">
                    Anonymous
                    <br />
                    Suggestion Box
                  </h5>
                </div>
                <div className="right-wrap d-flex align-items-center">
                  {dashboardMetrics?.anonymousCount}
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}

      {metricsIsLoading && (
        <Col className="text-center no-record mt-5">
          <output className="spinner-border"></output>
        </Col>
      )}

      {!userInfoFromLS?.isSuperAdminLogin && ( //Hide need help when login from admin launch from super admin portal
        <div className="need-help" onClick={() => navigate(pageURLs.support)}>
          <span className="d-flex align-items-center justify-content-center m-auto">
            {Strings.Dashboard.NeedHelp}
          </span>
        </div>
      )}
    </>
  );
};

export default Dashboard;
