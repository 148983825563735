/**
 * @file   src\components\ManageBuildings.tsx
 * @brief  Buildings CRUD.
 * @date   Sep, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import ICEditLink from "../assets/img/icons/EditLink";
import ICDeleteLink from "../assets/img/icons/DeleteLink";
import { HTTP_RESPONSE_STATUS_200, ZERO } from "../constants/common";
import { Button, Col, Row } from "react-bootstrap";
import Strings from "../assets/strings/Strings.json";
import { useState } from "react";
import {
  AddUpdteBuilding,
  DeleteBuilding,
  FormData,
  FormError,
} from "../interfaces/Buildings";
import { ENTER_BUILDING_NAME } from "../constants/validationMessages";
import DeleteConfModal from "../components/common/DeleteConfModal";
import AddUpateBuildingModal from "./AddUpateBuildingModal";
import Search from "./common/Search";
import { numberValidation } from "../helpers/validations";

const ManageBuildings = (props: any) => {
  const {
    handleSaveBuilding,
    buildingList,
    handleDelete,
    buildingListIsLoading,
    handleBuildingSearch,
    states,
  } = props;
  const initialData = {
    name: "",
    address1: "",
    address2: "",
    zip: "",
    city: "",
    state: "",
  };
  const initialError = {
    nameError: "",
  };
  //Initial state declarations
  const [formData, setFormData] = useState<FormData>(initialData);
  const [isShowAddModal, setAddEditModal] = useState<boolean>(false);
  const [formError, setFormError] = useState<FormError>(initialError);
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [isDisable, setIsDisabled] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteMdl] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");

  //Close add/edit modal
  const closeAddEditModal = () => {
    setFormData(initialData);
    setFormError(initialError);
    setAddEditModal(false);
  };

  const showAddEditModal = (editedItem: FormData) => {
    setFormData((data) => ({
      ...data,
      buildngId: editedItem?.id,
      name: editedItem?.name,
      address1: editedItem?.address_1,
      address2: editedItem?.address_2,
      city: editedItem?.city,
      zip: editedItem?.zip,
      state: editedItem?.state,
    }));

    setAddEditModal(true);
  };

  //Validating form data
  const validateFormData = () => {
    setFormError(initialError);
    let isValid = true;
    const buildingName = formData?.name?.trim();

    if (!buildingName) {
      setFormError((error) => ({
        ...error,
        nameError: ENTER_BUILDING_NAME,
      }));
      isValid = false;
    }

    return isValid;
  };

  //Handle delete confirmarmation modal show/hide
  const handleDeleteModal = (state: boolean) => {
    if (!state) {
      setFormData(initialData);
    }
    setShowDeleteMdl(state || false);
  };

  //This will trigger in text box changes and update into respective state objects.
  const onTextBoxChange = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    const currentId = e.currentTarget.id;

    setFormData((form: FormData) => ({
      ...form,
      [currentId]:
        currentId === "zip"
          ? !numberValidation.test(value)
            ? value
            : formData?.zip
          : value,
    }));
  };

  //Handle state drodown selection and update the value into the state
  const handleStateSelection = (selectedItem: string) => {
    setFormData((form: FormData) => ({
      ...form,
      state: selectedItem,
    }));
  };

  //Handle Add/Edit buidling click
  const handleSave = async () => {
    const isValid = validateFormData();
    if (isValid) {
      setIsLoad(true);
      const payload: AddUpdteBuilding = {
        name: formData?.name,
        address_1: formData?.address1,
        address_2: formData?.address2,
        city: formData?.city,
        state: formData?.state,
        zip: formData?.zip,
      };
      //Edit and update flow
      if (formData?.buildngId) {
        payload.id = formData?.buildngId;
      }
      const res: any = await handleSaveBuilding(payload, searchText);
      if (res?.status === HTTP_RESPONSE_STATUS_200) {
        setAddEditModal(false);
        setFormData(initialData);
      }
    }
    setIsLoad(false);
  };

  //Handle delete buidling confirmation click
  const handleBuildngDelete = async () => {
    if (formData?.buildngId) {
      setIsDisabled(true);
      const payload: DeleteBuilding = { id: formData?.buildngId };

      const res: any = await handleDelete(payload);
      if (res?.status === HTTP_RESPONSE_STATUS_200) {
        setFormData(initialData);
        handleDeleteModal(false);
      }
    }
    setIsDisabled(false);
  };

  //Const

  return (
    <>
      <div className="d-flex mb-3 mt-3 add-building">
        <Button variant="btn btn-primary" onClick={() => setAddEditModal(true)}>
          {Strings.buildings.addBuildingBtn}
        </Button>
        <Search
          onClick={handleBuildingSearch}
          searchText={searchText}
          setSearchText={setSearchText}
        />
      </div>

      <AddUpateBuildingModal
        show={isShowAddModal}
        closeModal={closeAddEditModal}
        handleSave={handleSave}
        formData={formData}
        formError={formError}
        states={states}
        isLoad={isLoad}
        onTextBoxChange={onTextBoxChange}
        handleStateSelection={handleStateSelection}
      />

      <Row>
        {buildingListIsLoading ? (
          <Col className="text-center no-record mt-5">
            <output className="spinner-border"></output>
          </Col>
        ) : buildingList && buildingList?.length > ZERO ? (
          buildingList?.map((item: any, index: number) => {
            return (
              <>
                <Col lg="4">
                  <div
                    className="sortable-wrapper manage-buildings d-flex align-items-center"
                    key={index}
                  >
                    <div className="detail-wrap d-flex justify-content-between align-items-center">
                      <div className="link-dtls">
                        <span> {item?.name} </span>
                      </div>
                      <div className="link-actions d-flex">
                        <span
                          className="d-flex align-items-center justify-content-center"
                          onClick={() => {
                            showAddEditModal(item);

                            setFormError(initialError);
                          }}
                        >
                          <ICEditLink />
                        </span>
                        <span
                          className="d-flex align-items-center justify-content-center"
                          onClick={() => {
                            handleDeleteModal(true);
                            setFormData((form: FormData) => ({
                              ...form,
                              buildngId: item?.id,
                            }));
                          }}
                        >
                          <ICDeleteLink />
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              </>
            );
          })
        ) : (
          <Col className="text-center mt-3 mb-3">
            {Strings.buildings.NoDataMessage}
          </Col>
        )}
      </Row>
      <DeleteConfModal
        onHide={handleDeleteModal}
        show={showDeleteModal}
        title={Strings.buildings.deleteModal.Title}
        desc={Strings.buildings.deleteModal.Info}
        handleDelete={handleBuildngDelete}
        isDisabled={isDisable}
      />
    </>
  );
};

export default ManageBuildings;
