import { createSlice } from "@reduxjs/toolkit";

import {
  fetchOrganizationDetails,
  fetchTimeZoneList,
} from "../actions/organization";
import { fetchStateLists } from "../actions/organization";
import { InitialState } from "../../interfaces/ReduxState";
import { slices } from "../../constants/common";

// Define the initial state using that type
const initialState: InitialState = {
  loading: false,
  orgDetails: [],
  timeZoneListLoading: false,
  timeZoneList: [],
  error: "",
};

//Slice for fetch organisation details and state list
const organizationReducer = createSlice({
  name: slices.organization,

  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOrganizationDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchOrganizationDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.orgDetails = action.payload?.data;
      state.error = "";
    });
    builder.addCase(fetchOrganizationDetails.rejected, (state, action) => {
      state.loading = false;
      state.list = [];
      state.error = action?.error?.message;
    });
    builder.addCase(fetchStateLists.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchStateLists.fulfilled, (state, action) => {
      state.loading = false;
      state.stateLists = action.payload?.data?.stateList;
      state.error = "";
    });
    builder.addCase(fetchStateLists.rejected, (state, action) => {
      state.loading = false;
      state.stateLists = [];
      state.error = action?.error?.message;
    });

    builder.addCase(fetchTimeZoneList.pending, (state) => {
      state.timeZoneListLoading = true;
    });
    builder.addCase(fetchTimeZoneList.fulfilled, (state, action) => {
      state.timeZoneListLoading = false;
      state.timeZoneList = action.payload?.data?.timezoneList;
      state.error = "";
    });
    builder.addCase(fetchTimeZoneList.rejected, (state) => {
      state.timeZoneListLoading = false;
      state.timeZoneList = null;
    });
  },
});

export default organizationReducer.reducer;
