/**
 * @file   src\components\SLCheckIn.tsx
 * @brief  CheckIn component.
 * @date   Feb, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import "./SLCheckIn.scss";
import ICCheckinArrow from "../assets/img/icons/CheckInArrow";
import ICCheckinArrowRight from "../assets/img/icons/CheckInArrowRight";
import { dateFormat } from "../helpers/common";
import {
  ACTIVE,
  DATE_FORMAT_TIME,
  DATE_FORMAT_WITHOUT_TIME_FULL_YEAR,
  EMERGENCY_CHK_INS,
  SESSION_ACTIVE,
} from "../constants/common";
const SLCheckIn = (props: any) => {
  const { data, activeClass, navigate, page, itemKey, timeZone } = props;
  let outerDivClass = `checkin-wrap d-flex`;
  if (
    data.isActive === SESSION_ACTIVE ||
    data.session_Status === SESSION_ACTIVE
  ) {
    outerDivClass = `checkin-wrap d-flex ${activeClass}`;
  }

  return (
    <div className={outerDivClass} onClick={navigate} key={itemKey}>
      {page === EMERGENCY_CHK_INS ? (
        <div className="left-wrap">
          {dateFormat(data?.createdOn, DATE_FORMAT_WITHOUT_TIME_FULL_YEAR)} at{" "}
          {dateFormat(data?.createdOn, DATE_FORMAT_TIME)} {timeZone} requested
          by{" "}
          <span>
            {data?.firstName} {data?.lastName}
          </span>
        </div>
      ) : (
        <div className="left-wrap">
          {dateFormat(data?.session_Start, DATE_FORMAT_WITHOUT_TIME_FULL_YEAR)}{" "}
          at {dateFormat(data?.session_Start, DATE_FORMAT_TIME)} {timeZone} |{" "}
          <span>
            {data?.firstName} {data?.lastName}
          </span>
        </div>
      )}
      {page === EMERGENCY_CHK_INS ? (
        <div className="right-wrap">
          <span className="active-dtls">
            {data.isActive === SESSION_ACTIVE
              ? ACTIVE
              : `Deactivated: ${dateFormat(data?.deActivatedTS, DATE_FORMAT_WITHOUT_TIME_FULL_YEAR)} at ${dateFormat(
                  data?.deActivatedTS,
                  DATE_FORMAT_TIME
                )} ${timeZone}`}
            <ICCheckinArrow />
          </span>
          <span className="icon-wrap">
            <ICCheckinArrowRight />
          </span>
        </div>
      ) : (
        <div className="right-wrap">
          <span className="active-dtls">
            {data.session_Status === SESSION_ACTIVE
              ? ACTIVE
              : `Deactivated: ${dateFormat(data?.session_EndTime, DATE_FORMAT_WITHOUT_TIME_FULL_YEAR)} at ${dateFormat(
                  data?.session_EndTime,
                  DATE_FORMAT_TIME
                )} ${timeZone}`}
            <ICCheckinArrow />
          </span>
          <span className="icon-wrap">
            <ICCheckinArrowRight />
          </span>
        </div>
      )}
    </div>
  );
};

export default SLCheckIn;
